export default {
    getters: {
        GET_PLACE_WITH_VACANCIES: "getPlaceWithVacancies",
        GET_TICKET_CONFIGS: "getTicketConfigs",
        GET_TICKET_CONFIG_BY_PLACE_ID_HASH: "getTicketConfigByPlaceIdHash",
        GET_TICKET_CONFIG_DICTIONARY_FOR_TICKETS: "getTicketConfigDictionaryForTickets",
        GET_PLACE_WITH_VACANCY_BY_PLACE_ID_HASH: "getPlaceWithVacancyByPlaceIdHash",
        HAS_TO_SHOW_SPLASH: "hasToShowSplash",
        HAS_REQUEST_ERROR: "hasRequestError",
        GET_QUESTIONNAIRE_URL: "getQuestionnaireUrl",
    },
    mutations: {
        SET_PLACES: "setPlaces",
        SET_VACANCIES: "setVacancies",
        SET_TICKET_CONFIGS: "setTicketConfigs",
        SET_TICKET_CONFIG_FOR_TICKET: "setTicketConfigForTicket",
        SORT_PLACES_BY_VACANCY_STATUS: "sortPlacesByVacancyStatus",
        SET_HAS_TO_SHOW_SPLASH: "setHasToShowSplash",
        SET_REQUEST_ERROR: "setRequestError",
        REMOVE_REQUEST_ERROR: "removeRequestError",
    },
    actions: {
        FETCH_PLACES_WITH_VACANCY: "fetchPlacesWithVacancy",
        FETCH_TICKET_CONFIGS: "fetchTicketConfigs",
        FETCH_TICKET_CONFIG_FOR_TICKET: "fetchTicketConfigForTicket",
        FETCH_VACANCY: "fetchVacancy",
    },
};
