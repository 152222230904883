import { ActionTree, MutationTree, GetterTree, Commit } from "vuex";
import types from "@/store/types";

import { OrganizationFilterState } from "./state";

export const mutations: MutationTree<OrganizationFilterState> = {
    [types.organization.filter.mutations.TOGGLE_FLOOR]: (state: OrganizationFilterState, floor: string) => {
        const floorIndex: number = state.filterList.indexOf(floor);
        floorIndex === -1 ? state.filterList.push(floor) : state.filterList.splice(floorIndex, 1);
    },
    [types.organization.filter.mutations.CLEAR_ALL_FLOOR]: (state: OrganizationFilterState) => {
        state.filterList.splice(0, state.filterList.length);
        state.isVacantOnly = false;
    },
    [types.organization.filter.mutations.TOGGLE_VACANY]: (state: OrganizationFilterState) => {
        state.isVacantOnly = !state.isVacantOnly;
    },
};
