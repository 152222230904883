export function save(key: string, value: any): boolean {
    try {
        localStorage.setItem(`vmodal/${key}`, JSON.stringify(value));
        return true;
    } catch {
        return false;
    }
}

export function load(key: string): any {
    const storedItem = localStorage.getItem(`vmodal/${key}`);
    return storedItem !== null ? JSON.parse(storedItem) : undefined;
}

export function clear(key: string): void {
    localStorage.removeItem(`vmodal/${key}`);
}
