import Vue from "vue";
import { ActionContext, MutationTree, ActionTree, GetterTree, Dictionary } from "vuex";
import { VacanserviceAdapterApi } from "@vacancorp/vacanservice.adapter.api.vacanservice.com";
import { Vacancy, PlaceWithVacancy } from "noline";

import {
    fetchPlaceListByPlaceGroupIdHashList,
    fetchTicketConfigListByPlaceIdHashList,
    fetchVacancyListByPlaceIdHashList,
    fetchTicketConfigByTicketIdHash,
} from "@/modules/noline/api/vacanservice.adapter.api";
import types from "@/modules/noline/store/types";

export interface NolineState {
    places: VacanserviceAdapterApi.ResponseGetPlace[];
    vacancies: Vacancy[];
    sorted: boolean;
    hasToShowSplash: boolean;
    requestErrors: Record<string, number>;
    ticketConfigs: VacanserviceAdapterApi.ResponseGetTicketConfig[]; // ticketConfig by placeId
    ticketConfigForTicket: Dictionary<VacanserviceAdapterApi.TicketConfig>; // ticketConfig by ticketId
}

const state: NolineState = {
    places: [],
    vacancies: [],
    sorted: false,
    hasToShowSplash: true,
    requestErrors: {},
    ticketConfigs: [],
    ticketConfigForTicket: {},
};

const getters: GetterTree<NolineState, any> = {
    [types.getters.GET_PLACE_WITH_VACANCIES]: (state: NolineState): PlaceWithVacancy[] => {
        return state.places.reduce((prev: PlaceWithVacancy[], curr: VacanserviceAdapterApi.ResponseGetPlace) => {
            const placeVacancy = state.vacancies.find((vacancy: Vacancy) => curr.placeIdHash === vacancy.placeIdHash);

            if (placeVacancy !== undefined) {
                prev.push({ ...curr, ...placeVacancy });
            }
            return prev;
        }, []);
    },
    [types.getters.GET_TICKET_CONFIGS]: (state: NolineState): VacanserviceAdapterApi.ResponseGetTicketConfig[] => {
        return state.ticketConfigs;
    },
    [types.getters.GET_TICKET_CONFIG_BY_PLACE_ID_HASH]: (state: NolineState) => (
        placeIdHash: string,
    ): VacanserviceAdapterApi.ResponseGetTicketConfig | undefined => {
        return state.ticketConfigs.find(
            (ticketConfig: VacanserviceAdapterApi.ResponseGetTicketConfig) => ticketConfig.placeIdHash === placeIdHash,
        );
    },
    [types.getters.GET_TICKET_CONFIG_DICTIONARY_FOR_TICKETS]: (
        state: NolineState,
    ): Dictionary<VacanserviceAdapterApi.TicketConfig> => {
        return state.ticketConfigForTicket;
    },
    [types.getters.GET_PLACE_WITH_VACANCY_BY_PLACE_ID_HASH]: (state: NolineState) => (
        placeIdHash: string,
    ): Partial<PlaceWithVacancy> => {
        const place = state.places.find(
            (place: VacanserviceAdapterApi.ResponseGetPlace) => place.placeIdHash === placeIdHash,
        );
        const vacancy = state.vacancies.find((vacancy: Vacancy) => vacancy.placeIdHash === placeIdHash);

        return { ...place, ...vacancy };
    },
    [types.getters.HAS_TO_SHOW_SPLASH]: (state: NolineState): boolean => {
        return state.hasToShowSplash;
    },
    [types.getters.HAS_REQUEST_ERROR]: (state: NolineState): boolean => {
        return Object.values(state.requestErrors).some(count => count > 10);
    },
    [types.getters.GET_QUESTIONNAIRE_URL]: (state: NolineState): string => {
        // 現状「館」の設定というものはなく、館以下の店舗はすべて同じアンケートURLを持つという仕様のため、
        // 館に所属する店舗から見つかったものを館のアンケートURLとして利用する。
        const firstTicketConfig = state.ticketConfigs.find(
            ticketConfig => typeof ticketConfig.questionnaireUrl === "string",
        );
        if (firstTicketConfig !== undefined) {
            return firstTicketConfig.questionnaireUrl !== undefined ? firstTicketConfig.questionnaireUrl : "";
        } else {
            return "";
        }
    },
};

const mutations: MutationTree<NolineState> = {
    [types.mutations.SET_PLACES](state: NolineState, places: VacanserviceAdapterApi.ResponseGetPlace[]) {
        state.places = places;
    },
    [types.mutations.SET_VACANCIES](state: NolineState, vacancies: Vacancy[]) {
        state.vacancies = vacancies;
    },
    [types.mutations.SET_TICKET_CONFIGS](
        state: NolineState,
        ticketConfigs: VacanserviceAdapterApi.ResponseGetTicketConfig[],
    ) {
        state.ticketConfigs = ticketConfigs;
    },
    [types.mutations.SET_TICKET_CONFIG_FOR_TICKET](
        state: NolineState,
        config: {
            ticketIdHash: string;
            ticketConfig: VacanserviceAdapterApi.TicketConfig;
        },
    ) {
        state.ticketConfigForTicket[config.ticketIdHash] = config.ticketConfig;
    },
    [types.mutations.SORT_PLACES_BY_VACANCY_STATUS](state: NolineState) {
        const vacancyList: Vacancy[] = state.vacancies;

        enum OrderStatus {
            VACANCY,
            WAITING,
            NOT_AVAILABLE,
            CLOSED,
        }
        function setStatus(vacancy: Vacancy): OrderStatus {
            const isLimitLeached = vacancy.useSizeLimit && vacancy.availableSize === 0;

            if (vacancy.status === "vacant" && !isLimitLeached) return OrderStatus.VACANCY;
            else if (vacancy.status === "waiting" && vacancy.isAvailable && !isLimitLeached) return OrderStatus.WAITING;
            else if (vacancy.status !== "closed" && (!vacancy.isAvailable || isLimitLeached))
                return OrderStatus.NOT_AVAILABLE;
            else return OrderStatus.CLOSED;
        }

        function extractInteger(string: string): number {
            return parseInt(string.replace(/[^0-9]/g, ""), 10);
        }

        const sortedVacancyList = vacancyList.sort(
            (a: Vacancy, b: Vacancy): number => {
                let orderA = setStatus(a);
                let orderB = setStatus(b);

                if (orderA !== orderB) {
                    return orderA - orderB;
                } else if (orderA === OrderStatus.WAITING) {
                    return extractInteger(a.displayVacancyText) - extractInteger(b.displayVacancyText);
                } else {
                    return a.placeIdHash < b.placeIdHash ? 1 : -1;
                }
            },
        );

        state.places = state.places.sort(
            (a, b) =>
                sortedVacancyList.findIndex(vacancy => vacancy.placeIdHash === a.placeIdHash) -
                sortedVacancyList.findIndex(vacancy => vacancy.placeIdHash === b.placeIdHash),
        );

        state.sorted = true;
    },
    [types.mutations.SET_HAS_TO_SHOW_SPLASH](state: NolineState, show: boolean) {
        state.hasToShowSplash = show;
    },
    [types.mutations.SET_REQUEST_ERROR](state: NolineState, api: string) {
        const currentCount = state.requestErrors[api];
        state.requestErrors = {
            ...state.requestErrors,
            [api]: currentCount + 1 || 1,
        };
    },
    [types.mutations.REMOVE_REQUEST_ERROR](state: NolineState, api: string) {
        state.requestErrors[api] = 0;
    },
};

const actions: ActionTree<NolineState, any> = {
    async [types.actions.FETCH_PLACES_WITH_VACANCY](
        { commit, state }: ActionContext<NolineState, any>,
        placeGroupIdHash: string,
    ) {
        const placeList = await fetchPlaceListByPlaceGroupIdHashList(placeGroupIdHash);

        const vacancyList = await fetchVacancyListByPlaceIdHashList(
            placeList.map((place: VacanserviceAdapterApi.ResponseGetPlace) => place.placeIdHash).join(","),
        );

        commit(types.mutations.SET_PLACES, placeList);
        commit(types.mutations.SET_VACANCIES, vacancyList);

        if (state.sorted) {
            commit(types.mutations.SORT_PLACES_BY_VACANCY_STATUS);
        }
    },
    async [types.actions.FETCH_TICKET_CONFIGS]({ commit, state }: ActionContext<NolineState, any>) {
        const configList = await fetchTicketConfigListByPlaceIdHashList(
            state.vacancies.map((vacancy: Vacancy) => vacancy.placeIdHash).join(","),
        );
        commit(types.mutations.SET_TICKET_CONFIGS, configList);
    },
    async [types.actions.FETCH_TICKET_CONFIG_FOR_TICKET](
        { commit }: ActionContext<NolineState, any>,
        ticketIdHash: string,
    ) {
        const ticketConfig = await fetchTicketConfigByTicketIdHash(ticketIdHash);
        commit(types.mutations.SET_TICKET_CONFIG_FOR_TICKET, { ticketIdHash, ticketConfig });
    },
    async [types.actions.FETCH_VACANCY]({ commit }: ActionContext<NolineState, any>) {
        commit(
            types.mutations.SET_VACANCIES,
            await fetchVacancyListByPlaceIdHashList(
                state.places.map((place: VacanserviceAdapterApi.ResponseGetPlace) => place.placeIdHash).join(","),
            ),
        );
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
