var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"frame",staticClass:"frame"},[_c('div',{staticClass:"map-area",style:({
      width: ((_vm.zoomedMapSize.width + _vm.margin.left + _vm.margin.right) + "px"),
      height: ((_vm.zoomedMapSize.height + _vm.margin.top + _vm.margin.bottom) + "px"),
      padding: ((_vm.margin.top) + "px " + (_vm.margin.right) + "px " + (_vm.margin.bottom) + "px " + (_vm.margin.left) + "px"),
    })},[_c('img',{staticClass:"map-image",style:({
        width: ((_vm.zoomedMapSize.width) + "px"),
        height: ((_vm.zoomedMapSize.height) + "px"),
      }),attrs:{"src":_vm.mapImage.url}}),_c('div',{staticClass:"pins"},[(_vm.start !== undefined)?_c('img',{staticClass:"start-position",style:({
          left: ((_vm.zoomedStartPoint.x + _vm.margin.left) + "px"),
          top: ((_vm.zoomedStartPoint.y + _vm.margin.top) + "px"),
        }),attrs:{"src":require(("@/assets/cavi/current-location/" + (_vm.start.direction || 'none') + ".svg"))}}):_vm._e(),(_vm.start !== undefined)?_c('span',{staticClass:"start-label",style:({
          width: ((_vm.startIconSize.width) + "px"),
          left: ((_vm.zoomedStartPoint.x + _vm.margin.left + _vm.startLabelMargin(_vm.start.direction).left) + "px"),
          top: ((_vm.zoomedStartPoint.y + _vm.margin.top + _vm.startLabelMargin(_vm.start.direction).top) + "px"),
        })},[_vm._v(" 現在地 ")]):_vm._e(),(_vm.arrival !== undefined)?_c('img',{staticClass:"arrival-position",style:({
          left: ((_vm.zoomedArrivalPoint.x + _vm.margin.left) + "px"),
          top: ((_vm.zoomedArrivalPoint.y + _vm.margin.top) + "px"),
        }),attrs:{"src":require(("@/assets/cavi/arrival-location/" + (_vm.arrival.direction || 'none') + ".svg"))}}):_vm._e(),(_vm.arrival !== undefined)?_c('span',{staticClass:"start-label",style:({
          width: ((_vm.startIconSize.width) + "px"),
          left: ((_vm.zoomedArrivalPoint.x + _vm.margin.left + _vm.arrivalLabelMargin(_vm.arrival.direction).left) + "px"),
          top: ((_vm.zoomedArrivalPoint.y + _vm.margin.top + _vm.arrivalLabelMargin(_vm.arrival.direction).top) + "px"),
        })},[_vm._v(" 到着地 ")]):_vm._e(),(_vm.destination !== undefined)?_c('img',{staticClass:"destination-position",style:({
          left: ((_vm.zoomedDestinationPoint.x + _vm.margin.left) + "px"),
          top: ((_vm.zoomedDestinationPoint.y + _vm.margin.top) + "px"),
        }),attrs:{"src":require("@/assets/cavi/pins/destination.svg")}}):_vm._e(),(_vm.destination !== undefined)?_c('span',{staticClass:"destination-label",style:({
          width: "39px",
          left: ((_vm.zoomedDestinationPoint.x + _vm.margin.left) + "px"),
          top: ((_vm.zoomedDestinationPoint.y + _vm.margin.top - 18) + "px"),
        })},[_vm._v(" 目的地 ")]):_vm._e(),_vm._l((_vm.connectorList),function(connector){return [_c('img',{key:connector.id,staticClass:"connector",class:{ disabled: connector.disabled },style:({
            left: ((_vm.getZoomedConnectorPoint(connector).x + _vm.margin.left) + "px"),
            top: ((_vm.getZoomedConnectorPoint(connector).y + _vm.margin.top) + "px"),
          }),attrs:{"src":require("@/assets/cavi/pins/connector-pin.svg")},on:{"click":function($event){return _vm.onSelectPin(connector)}}}),(connector.type)?_c('img',{key:((connector.id) + "-icon"),staticClass:"connector-icon",style:({
            left: ((_vm.getZoomedConnectorPoint(connector).x + _vm.margin.left + 9) + "px"),
            top: ((_vm.getZoomedConnectorPoint(connector).y + _vm.margin.top + 7) + "px"),
          }),attrs:{"src":require(("@/assets/cavi/connector-types/" + (connector.type) + ".svg"))},on:{"click":function($event){return _vm.onSelectPin(connector)}}}):_vm._e(),_c('span',{key:((connector.id) + "-label"),staticClass:"connector-label",style:({
            left: ((_vm.getZoomedConnectorPoint(connector).x + _vm.margin.left + 7) + "px"),
            top: ((_vm.getZoomedConnectorPoint(connector).y + _vm.margin.top - 16) + "px"),
          })},[_vm._v("次へ")])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }