import { RouteConfig } from "vue-router";

import Frame from "@/modules/noline/index.vue";
import Places from "@/modules/noline/views/places.vue";
import Entry from "@/modules/noline/views/place.vue";
import EntryConfirmation from "@/modules/noline/views/entry-confirmation.vue";
import EntryCompletion from "@/modules/noline/views/entry-completion.vue";
import EntryFailure from "@/modules/noline/views/entry-failure.vue";
import EntryEmail from "@/modules/noline/views/entry-email.vue";
import EntryEmailCompletion from "@/modules/noline/views/entry-email-completion.vue";
import EntryEmailVerified from "@/modules/noline/views/entry-email-verified.vue";
import EntryEmailConflicted from "@/modules/noline/views/entry-email-conflicted.vue";
import EntryEmailInvalidActivation from "@/modules/noline/views/entry-email-invalid-activation.vue";
import Error from "@/modules/noline/views/error.vue";
import ReservationFailure from "@/modules/noline/views/reservation-failure.vue";
import Ticket from "@/modules/noline/views/ticket.vue";

import storage from "@/modules/noline/api/local-storage";
import store from "@/store";
import types from "@/modules/noline/store/types";

import { postTickets, verifyPhoneNumber, verifyEmail } from "@/modules/noline/api/vacanservice.adapter.api";

const routes: RouteConfig[] = [
    {
        path: "/join",
        component: Frame,
        children: [
            {
                path: ":requestIdHash",
                component: Error,
                beforeEnter: async (to, _, next) => {
                    const hash: string = to.params.requestIdHash;

                    try {
                        const result = await postTickets(hash);

                        if (result === undefined || result.ticketIdHash === undefined) {
                            throw new Error();
                        }

                        await store.dispatch(types.actions.FETCH_TICKET_CONFIG_FOR_TICKET, result.ticketIdHash);
                        const ticketConfig = store.state.noline.ticketConfigForTicket[result.ticketIdHash];

                        if (ticketConfig.notificationTarget === "email") {
                            storage.save(`${result.ticketIdHash}/didUserSendEmail`, true);
                        }

                        next({
                            name:
                                ticketConfig.notificationTarget === "email"
                                    ? "noline/entry/email/verified"
                                    : "noline/tickets",
                            params: { ticketIdHash: result.ticketIdHash },
                            replace: true,
                        });
                        return;
                    } catch (error) {
                        if (error.status === 422) {
                            const placeGroupIdHash = error.data !== undefined ? error.data.placeGroupIdHash : undefined;

                            next({
                                name: "noline/tickets/failure",
                                query: {
                                    placeGroupIdHash,
                                },
                                replace: true,
                            });
                            return;
                        }

                        next();
                    }
                },
            },
        ],
    },
    {
        path: "/verify",
        component: Frame,
        children: [
            {
                path: ":activationHash",
                component: Error,
                beforeEnter: (to, _, next) => {
                    const activationHash: string = to.params.activationHash;

                    verifyPhoneNumber(activationHash)
                        .then(result => {
                            if (
                                result === undefined ||
                                result.isActivated !== true ||
                                result.ticketIdHash === undefined
                            ) {
                                throw new Error();
                            }
                            next({
                                name: "noline/tickets",
                                params: { ticketIdHash: result.ticketIdHash },
                                replace: true,
                            });
                            return;
                        })
                        .catch(() => next());
                },
            },
        ],
    },
    {
        path: "/tickets",
        component: Frame,
        beforeEnter(_from, _to, next) {
            store.commit(types.mutations.SET_HAS_TO_SHOW_SPLASH, false);
            next();
        },
        children: [
            {
                path: "failure",
                name: "noline/tickets/failure",
                component: ReservationFailure,
            },
            {
                path: ":ticketIdHash",
                name: "noline/tickets",
                component: Ticket,
            },
            {
                path: ":ticketIdHash/email/conflicted",
                name: "noline/entry/email/conflicted",
                component: EntryEmailConflicted,
            },
            {
                path: ":ticketIdHash/email/verified",
                name: "noline/entry/email/verified",
                component: EntryEmailVerified,
            },
            {
                path: ":ticketIdHash/email/activation/failed",
                name: "noline/entry/email/activation/failed",
                component: EntryEmailInvalidActivation,
            },
            {
                path: ":ticketIdHash/email/verify/:activationHash",
                name: "noline/entry/email/verify",
                component: Error,
                beforeEnter: (to, _, next) => {
                    const activationHash: string = to.params.activationHash;

                    verifyEmail(activationHash)
                        .then(result => {
                            if (
                                result === undefined ||
                                result.isActivated !== true ||
                                result.ticketIdHash === undefined
                            ) {
                                throw new Error();
                            }
                            next({
                                name: "noline/entry/email/verified",
                                params: { ticketIdHash: result.ticketIdHash },
                                replace: true,
                            });
                            return;
                        })
                        .catch(error => {
                            // Prepare new 'AlreadyEnabled' page when needed

                            // if (error.errorCode === "alreadyEnabled") {
                            //     next({
                            //         name: "noline/entry/email/verified",
                            //         params: to.params,
                            //         replace: true,
                            //     });
                            //     return;
                            // }
                            next({
                                name: "noline/entry/email/activation/failed",
                                params: to.params,
                                replace: true,
                            });
                        });
                },
            },
            {
                path: ":ticketIdHash/email/completion",
                name: "noline/tickets/email/completion",
                component: EntryEmailCompletion,
            },
            {
                path: ":ticketIdHash/email",
                name: "noline/tickets/email",
                component: EntryEmail,
            },
        ],
    },
    {
        path: "/noline/:placeGroupIdHash",
        component: Frame,
        redirect: to => {
            return { name: "noline/places", params: to.params };
        },
        beforeEnter(to, _, next) {
            if (
                ["G7nmNXWP", "JjX5krNz", "L5r0DrOy", "wZnxpnoa", "jbrAvnxJ", "WZ9N1X8E", "RJrBOnYk"].includes(
                    to.params.placeGroupIdHash,
                )
            ) {
                window.location.replace(`https://pages.vacan.com/${to.params.placeGroupIdHash}`);
            } else if (to.params.placeGroupIdHash === "LpXGE3rM") {
                window.location.replace("https://pages.vacan.com/3b72a054212047");
            }
            if (to.name !== "noline/places") {
                store.commit(types.mutations.SET_HAS_TO_SHOW_SPLASH, false);
            }
            next();
        },
        children: [
            {
                path: "places/:placeIdHash/entry/success",
                name: "noline/entry/success",
                component: EntryCompletion,
            },
            {
                path: "places/:placeIdHash/entry/failure",
                name: "noline/entry/failure",
                component: EntryFailure,
            },
            {
                path: "places/:placeIdHash/entry/confirmation",
                name: "noline/entry/confirmation",
                component: EntryConfirmation,
                beforeEnter: (to, from, next) => {
                    if (storage.load(`${to.params.placeIdHash}/userInput`) === undefined) {
                        next({ name: "noline/places", params: to.params });
                        return;
                    }
                    next();
                },
            },
            {
                path: "places/:placeIdHash/entry",
                name: "noline/entry",
                component: Entry,
                beforeEnter: (to, _, next) => {
                    if (to.params.placeGroupIdHash === "G7nmNXWP") {
                        const campaignCodeList = [
                            { id: "vRnlmEXE", code: "KINDAIUNIVERSITY%20HANARE" },
                            { id: "QL9YLzX3", code: "ARISO%20SUSHI" },
                            { id: "8oXOqPrM", code: "NIKUOROSHIKOJIMA" },
                            { id: "w4Xq88nj", code: "NURUKANSATO" },
                            { id: "gL94gmX4", code: "Sobaniwa" },
                            { id: "OQ9Voqr0", code: "Maisenshokudo" },
                            { id: "WyXPeg9d", code: "KAMINARI" },
                            { id: "Zk9omlng", code: "HitachinoBrewingMarunouchi" },
                            { id: "Q0XQ4Gn5", code: "THE%20BEAT%20DINER" },
                            { id: "3RnaEBnM", code: "TOKYOBAR" },
                            { id: "w4Xq8Lnj", code: "CITYSHOP" },
                            { id: "159emw9v", code: "TOYOKEN" },
                            { id: "OQ9Vo0r0", code: "MIKATA" },
                            { id: "LpXGpG9M", code: "THE%20CENTRAL" },
                            { id: "Zk9ombng", code: "uochu" },
                            { id: "w19L8x96", code: "NangokuShuka47china" },
                            { id: "j3nDbG9v", code: "ITOWASHIGORO" },
                            { id: "Wg9MbLrd", code: "SeafoodPub%20HANEDAICHIBA" },
                            { id: "yOXz0Vrw", code: "Sushi%20HANEDAICHIBA" },
                            { id: "oGn3BKrx", code: "Tokyo%20Gyoza%20Stand%20Oolong" },
                            { id: "Q0XQ4Ln5", code: "Tokyo%20Station%20Beer%20Stand" },
                            { id: "WyXPeD9d", code: "KAKUNOSHIN" },
                            { id: "1vX2ka9Q", code: "SAKURA" },
                            { id: "gL94gZX4", code: "KITADETACOS" },
                            { id: "159emp9v", code: "DEPOT" },
                            { id: "8oXOqgrM", code: "NAKAMURAYA" },
                            { id: "oGn3BZrx", code: "RIKYU" },
                            { id: "LpXGp19M", code: "TsukijiSushiseiGRANSTA" },
                            { id: "1vX2kK9Q", code: "mango%20tree%20kitchen" },
                            { id: "vmrpmEnl", code: "NIPPON%20RAMEN%20RIN%20TOKYO" },
                            { id: "3RnaEvnM", code: "TsTantan" },
                        ];
                        const campaignCode = campaignCodeList.find(campaign => to.params.placeIdHash === campaign.id);

                        window.location.replace(
                            `https://pages.vacan.com/${to.params.placeGroupIdHash}/places/${
                                to.params.placeIdHash
                            }?utm_source=web&utm_medium=app${campaignCode ? `&utm_campaign=${campaignCode.code}` : ""}`,
                        );
                    } else if (
                        ["JjX5krNz", "L5r0DrOy", "wZnxpnoa", "jbrAvnxJ", "WZ9N1X8E", "RJrBOnYk"].includes(
                            to.params.placeGroupIdHash,
                        )
                    ) {
                        window.location.replace(
                            `https://pages.vacan.com/${to.params.placeGroupIdHash}/places/${to.params.placeIdHash}`,
                        );
                    } else if (to.params.placeGroupIdHash === "LpXGE3rM") {
                        window.location.replace("https://pages.vacan.com/3b72a054212047");
                    }
                    next();
                },
            },
            {
                path: "places",
                name: "noline/places",
                component: Places,
            },
        ],
    },
];

export default routes;
