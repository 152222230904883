import { GetterTree } from "vuex";
import types from "@/store/types";

import { Shop } from "vacan-api";

import { OrganizationFilterState } from "./state";

export const getters: GetterTree<OrganizationFilterState, any> = {
    selectedFilterCount: (state: OrganizationFilterState) => (): number => {
        return state.filterList.length;
    },
    filteredShopList: (state: OrganizationFilterState, _: any, rootState: any) => (): Shop[] => {
        return state.filterList.length === 0 || (state.filterList[0] === "空" && state.filterList.length === 1)
            ? rootState.organization.shopList
            : rootState.organization.shopList.filter(
                  (shop: Shop) => shop.floor !== null && state.filterList.indexOf(shop.floor) !== -1,
              );
    },
};
