import { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import $http from "@/api/http";

import { CouponDiscountUnit, CouponTargetVacancyState, Coupon } from "vacan-api";

export default class {
    public static async getCouponListByShopId(shopId: number): Promise<Coupon[]> {
        return await $http
            .get(`/shops/${shopId}/coupons/all`)
            .then((response: AxiosResponse) => response.data.items as Coupon[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getActiveCouponListByShopId(shopId: number): Promise<Coupon[]> {
        return await $http
            .get(`/shops/${shopId}/coupons`)
            .then((response: AxiosResponse) => response.data.items as Coupon[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getCouponListByOrganizationId(organizationId: number): Promise<Coupon[]> {
        return await $http
            .get(`/organizations/${organizationId}/coupons/all`)
            .then((response: AxiosResponse) => response.data.items as Coupon[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getActiveCouponListByOrganizationId(organizationId: number): Promise<Coupon[]> {
        return await $http
            .get(`/organizations/${organizationId}/coupons`)
            .then((response: AxiosResponse) => response.data.items as Coupon[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async insert(
        shopId: number,
        name: string,
        description: string,
        caption: string,
        discountAmount: number,
        discountUnit: CouponDiscountUnit,
        issueStartDatetime: string,
        issueEndDatetime: string,
        availableStartTime: string,
        availableEndTime: string,
        targetVacancyState: CouponTargetVacancyState,
        limitHeadcount: number,
    ): Promise<Coupon | null> {
        return await $http
            .post(`/shops/${shopId}/coupons`, {
                name,
                description,
                caption,
                discountAmount,
                discountUnit,
                issueStartDatetime,
                issueEndDatetime,
                availableStartTime,
                availableEndTime,
                targetVacancyState,
                limitHeadcount,
            })
            .then(
                (response: AxiosResponse) =>
                    response.data.items.length === 0 ? null : (response.data.items[0] as Coupon),
            )
            .catch(
                (error: AxiosError) =>
                    error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
    }

    public static async update(coupon: Coupon): Promise<Coupon | null> {
        return await $http
            .put(`/shops/${coupon.shopId}/coupons/${coupon.couponId}`, coupon)
            .then(
                (response: AxiosResponse) =>
                    response.data.items.length === 0 ? null : (response.data.items[0] as Coupon),
            )
            .catch(
                (error: AxiosError) =>
                    error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
    }

    public static async delete(shopId: number, couponId: number): Promise<void> {
        return await $http
            .delete(`/shops/${shopId}/coupons/${couponId}`)
            .then((response: AxiosResponse) => {
                return;
            })
            .catch(
                (error: AxiosError) =>
                    error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
    }
}
