export default {
    getters: {
        GET_CURRENT_MAP: "getCurrentMap",
        GET_CURRENT_MAP_POINT: "getCurrentMapPoint",
        GET_HAS_ERROR_IN_FETCH_MAP_POINT: "getHasErrorInFetchMapPoint",
    },
    mutations: {
        SET_CURRENT_MAP_POINT: "setCurrentMapPoint",
        SET_CURRENT_MAP: "setCurrentMap",
        SET_HAS_ERROR_IN_FETCH_MAP_POINT: "setHasErrorInFetchMapPoint",
    },
    actions: {
        FETCH_CURRENT_MAP_POINT: "fetchCurrentMapPoint",
    },
};
