import { AxiosResponse, AxiosError } from "axios";
import { ActionTree, MutationTree, GetterTree, Commit } from "vuex";
import types from "@/store/types";

import { Coupon, Organization, Shop, ShopTranslation, VacancyShop, VacancyState } from "vacan-api";

import CouponApi from "@/api/coupon";
import OrganizationApi from "@/api/organization";
import ShopApi from "@/api/shop";
import VacancyApi from "@/api/vacancy";

export interface RootState {
    shopList: Shop[];
    organizationList: Organization[];
    couponList: Coupon[];
    vacancyList: VacancyShop[];
}

const state: RootState = {
    shopList: [],
    organizationList: [],
    couponList: [],
    vacancyList: [],
};

const mutations: MutationTree<RootState> = {
    [types.mutations.SET_SHOP_LIST]: (state: RootState, shopList: Shop[]) => {
        state.shopList = shopList;
    },
    [types.mutations.SET_ORGANIZATION_LIST]: (state: RootState, organizationList: Organization[]) => {
        state.organizationList = organizationList;
    },
    [types.mutations.SET_COUPON_LIST]: (state: RootState, couponList: Coupon[]) => {
        state.couponList = couponList;
    },
    [types.mutations.SET_VACANCY_LIST]: (state: RootState, vacancyList: VacancyShop[]) => {
        state.vacancyList = vacancyList;
    },
};

const actions: ActionTree<RootState, any> = {
    // TODO: Give geometory parameter to this function
    [types.actions.LOAD_BY_GEOMETORY]: async ({ commit }: { commit: Commit }): Promise<void> => {
        // FIXME: This is temporary implementation for JOINUS and Takashimaya
        const joinusShopList: Shop[] = await ShopApi.getShopListByOrganizationId(3);
        const takashimayaShopList: Shop[] = await ShopApi.getShopListByOrganizationId(4);
        commit(types.mutations.SET_SHOP_LIST, joinusShopList.concat(takashimayaShopList));

        const joinusOrganization: Organization | undefined = await OrganizationApi.getOrganizationByOrganizationId(3);
        const takashimayaOrganization: Organization | undefined = await OrganizationApi.getOrganizationByOrganizationId(
            4,
        );
        commit(types.mutations.SET_ORGANIZATION_LIST, [joinusOrganization, takashimayaOrganization]);

        const joinusCouponList: Coupon[] = await CouponApi.getActiveCouponListByOrganizationId(3);
        commit(types.mutations.SET_COUPON_LIST, joinusCouponList);

        const joinusVacancyList: VacancyShop[] = await VacancyApi.getVacancyByOrganizationIdAndAreaIdList(3);
        const takashimayaVacancyList: VacancyShop[] = await VacancyApi.getVacancyByOrganizationIdAndAreaIdList(4);
        commit(types.mutations.SET_VACANCY_LIST, joinusVacancyList.concat(takashimayaVacancyList));
    },
};

const getters: GetterTree<RootState, any> = {
    shopValueByShopId: (state: RootState) => (shopId: number, key: keyof Shop) => {
        const shop: Shop | undefined = state.shopList.filter((shop: Shop) => shop.shopId === shopId).shift();
        return shop !== undefined ? shop[key] : undefined;
    },
    organizationValueShopId: (state: RootState, getters: any) => (shopId: number, key: keyof Organization) => {
        const organizationId: number = getters["shopValueByShopId"](shopId, "organizationId");
        const organization: Organization | undefined = state.organizationList
            .filter((organization: Organization) => organization.organizationId === organizationId)
            .shift();
        return organization !== undefined ? organization[key] : undefined;
    },
    vacancyShopByShopId: (state: RootState) => (shopId: number): VacancyShop => {
        return (
            state.vacancyList.filter((vacancyShop: VacancyShop) => vacancyShop.shopId === shopId)[0] || {
                shopId: shopId,
                usedPercentage: 0,
                waitingSeconds: 0,
                inputSource: "manual",
                vacancyState: "vacant",
                unixtime: new Date().valueOf() / 1000,
            }
        );
    },
    isCouponExistsByShopId: (state: RootState) => (shopId: number): boolean => {
        return state.couponList.filter((coupon: Coupon) => coupon.shopId === shopId).length > 0;
    },
};

export default {
    state,
    mutations,
    actions,
    getters,
};
