export default {
    actions: {
        LOAD_BY_GEOMETORY: "loadByGeometory",
    },
    mutations: {
        SET_SHOP_LIST: "setShopList",
        SET_ORGANIZATION_LIST: "setOrganizationList",
        SET_COUPON_LIST: "setCouponList",
        SET_VACANCY_LIST: "setVacancyList",
    },

    // UserSession
    USER_SESSION_M_SET_SESSION: "setSession",
    USER_SESSION_M_SET_CURRENT_ROLE: "setCurrentRole",
    USER_SESSION_M_LOGOUT: "logout",

    USER_SESSION_A_CREATE_SESSION: "createSession",
    USER_SESSION_A_RENEW_SESSION: "renewSession",
    USER_SESSION_A_FETCH_USER_ROLE: "fetchUserRole",

    // Admin
    ADMIN_M_SET_SHOP_LIST: "setUserShopList",
    ADMIN_M_SET_USER_LIST: "setUserShopUserList",
    ADMIN_M_PUSH_USER_ON_HEAD: "insertUserIntoUserListAtBeginning",
    ADMIN_M_DELETE_USER_BY_USER_ID: "deleteUserInUserListByUserId",

    ADMIN_A_FETCH_SHOP_LIST_BY_USER_ID: "fetchUserShopListByUserId",
    ADMIN_A_FETCH_USER_LIST_BY_SHOP_ID: "fetchUserShopUserListByShopId",
    ADMIN_A_INVITE_USER_TO_SHOP: "inviteUserForShop",
    ADMIN_A_DELETE_USER_FROM_SHOP: "deleteUserForShop",

    // AdminShop
    ADMIN_SHOP_M_SET: "setShop",
    ADMIN_SHOP_M_SET_SHOP_ID: "setShopId",
    ADMIN_SHOP_M_PUSH_SCHEDULE: "pushScheduleToList",
    ADMIN_SHOP_M_UPDATE_SCHEDULE_BY_SCHEDULE_ID: "updateScheduleByScheduleId",
    ADMIN_SHOP_M_UPDATE_SCHEDULE_PARTIALLY_BY_SCHEDULE_ID: "updateSchedulePartiallyByScheduleId",
    ADMIN_SHOP_M_DELETE_BY_SCHEDULE_ID: "deleteScheduleByScheduleId",
    ADMIN_SHOP_M_PUSH_IMAGE_COLLECTION: "pushImageCollection",
    ADMIN_SHOP_M_DELETE_IMAGE_COLLECTION: "deleteImageCollection",

    ADMIN_SHOP_A_INIT: "fetchUserShopByShopId",
    ADMIN_SHOP_A_UPDATE_SHOP: "updateUserShop",
    ADMIN_SHOP_A_INSERT_DEFAULT_SCHEDULE: "insertDefaultSchedule",
    ADMIN_SHOP_A_UPDATE_SCHEDULE_BY_SCHEDULE_ID: "updateScheduleByScheduleId",
    ADMIN_SHOP_A_DELETE_SCHEDULE: "deleteSchedule",
    ADMIN_SHOP_A_INSERT_IMAGE_COLLECTION: "insertImageCollection",
    ADMIN_SHOP_A_DELETE_IMAGE_COLLECTION: "deleteImageCollection",

    // AdminShopCoupon
    ADMIN_SHOP_COUPON_M_SET_COUPON_LIST: "setCouponList",
    ADMIN_SHOP_COUPON_M_SET_LATEST_UPDATED_COUPON_ID: "setLatestUpdatedCouponId",
    ADMIN_SHOP_COUPON_M_APPEND_COUPON: "appendCoupon",
    ADMIN_SHOP_COUPON_M_UPDATE_COUPON: "updateCoupon",
    ADMIN_SHOP_COUPON_M_REMOVE_COUPON: "removeCoupon",

    ADMIN_SHOP_COUPON_A_FETCH_COUPON_LIST_BY_SHOP_ID: "fetchCouponListByShopId",
    ADMIN_SHOP_COUPON_A_INSERT: "insertCoupon",
    ADMIN_SHOP_COUPON_A_UPDATE: "updateCoupon",
    ADMIN_SHOP_COUPON_A_DELETE: "deleteCoupon",

    // AdminShopLayout
    ADMIN_SHOP_LAYOUT_M_SET_LINE_LIST: "setLineList",
    ADMIN_SHOP_LAYOUT_M_APPEND_LINE: "appendLine",
    ADMIN_SHOP_LAYOUT_M_UPDATE_LINE: "updateLine",
    ADMIN_SHOP_LAYOUT_M_UPDATE_LINE_LAYOUT: "updateLineLayout",
    ADMIN_SHOP_LAYOUT_M_REMOVE_LINE: "removeLine",
    ADMIN_SHOP_LAYOUT_M_SET_TABLE_LIST: "setTableList",
    ADMIN_SHOP_LAYOUT_M_APPEND_TABLE: "appendTable",
    ADMIN_SHOP_LAYOUT_M_UPDATE_TABLE: "updateTable",
    ADMIN_SHOP_LAYOUT_M_UPDATE_TABLE_LAYOUT: "updateTableLayout",
    ADMIN_SHOP_LAYOUT_M_REMOVE_TABLE: "removeTable",

    ADMIN_SHOP_LAYOUT_A_FETCH_LINE_LIST_BY_SHOP_ID: "fetchLineListByShopId",
    ADMIN_SHOP_LAYOUT_A_INSERT_LINE: "insertLine",
    ADMIN_SHOP_LAYOUT_A_UPDATE_LINE: "updateLine",
    ADMIN_SHOP_LAYOUT_A_UPDATE_LINE_LAYOUT: "updateLineLayout",
    ADMIN_SHOP_LAYOUT_A_DELETE_LINE: "deleteLine",
    ADMIN_SHOP_LAYOUT_A_FETCH_TABLE_LIST_BY_SHOP_ID: "fetchTableListByShopId",
    ADMIN_SHOP_LAYOUT_A_INSERT_TABLE: "insertTable",
    ADMIN_SHOP_LAYOUT_A_UPDATE_TABLE: "updateTable",
    ADMIN_SHOP_LAYOUT_A_UPDATE_TABLE_LAYOUT: "updateTableLayout",
    ADMIN_SHOP_LAYOUT_A_DELETE_TABLE: "deleteTable",

    // AdminShopMonitor
    ADMIN_SHOP_MONITOR_M_SET_MONITOR_LIST: "setMonitorList",
    ADMIN_SHOP_MONITOR_M_APPEND_MONITOR: "appendMonitor",
    ADMIN_SHOP_MONITOR_M_UPDATE_MONITOR: "updateMonitor",
    ADMIN_SHOP_MONITOR_M_DELETE_BY_MONITOR_ID: "deleteMonitorByMonitorId",

    ADMIN_SHOP_MONITOR_A_FETCH_MONITOR_LIST: "fetchMonitorListByShopId",
    ADMIN_SHOP_MONITOR_A_INSERT: "insertMonitor",
    ADMIN_SHOP_MONITOR_A_UPDATE: "updateMonitor",
    ADMIN_SHOP_MONITOR_A_DELETE_BY_SHOP_ID_AND_MONITOR_ID: "deleteMonitorByMonitorId",

    // AdminShopVacancy
    ADMIN_SHOP_VACANCY_M_SET_LATEST: "setLatestVacancy",

    ADMIN_SHOP_VACANCY_A_FETCH_LATEST_BY_SHOP_ID: "fetchLatestVacancyByShopId",
    ADMIN_SHOP_VACANCY_A_INSERT_SHOP: "insertVacancyShop",
    ADMIN_SHOP_VACANCY_A_INSERT_TABLE: "insertVacancyTable",

    shop: {
        mutations: {
            SET_SHOP: "setShop",
            SET_ORGANIZATION: "setOrganization",
            SET_AREA: "setArea",
            SET_COUPON_LIST: "setCouponList",
            SET_VACANCY: "setVacancy",
        },
        actions: {
            SETUP: "setup",
        },
    },

    organization: {
        mutations: {
            SET_ORGANIZATION: "setOrganization",
            SET_AREA_LIST: "setAreaList",
            SET_COUPON_LIST: "setCouponList",
            SET_SHOP_LIST: "setShopList",
            SET_VACANCY_SHOP_LIST: "setVacancyShopList",
            INCREMENT_ANIMATION_IMAGE_COUNTER: "incrementAnimationImageCounter",
            INCREMENT_ANIMATION_LOCALIZE_COUNTER: "incrementAnimationLocalizeCounter",
        },
        actions: {
            SETUP: "setup",
            LOOP: "loop",
            TEARDOWN: "teardown",
        },

        filter: {
            mutations: {
                TOGGLE_FLOOR: "toggleFloor",
                CLEAR_ALL_FLOOR: "clearAllFloor",
                TOGGLE_VACANY: "toggleVacancy",
            },
        },
    },

    gateway: {
        mutations: {
            SET_GATEWAY_LIST: "setGatewayList",
        },
        actions: {
            FETCH_GATWAY_LIST_BY_SHOP_ID: "FetchGatewayListByShopId",
        },
    },
};
