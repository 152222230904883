import Vue, { PluginObject } from "vue";

import { CouponDiscountUnit } from "vacan-api";

const install = (vue: typeof Vue) => {
    Vue.filter("discountUnit.ja", (discountUnit: CouponDiscountUnit): string => {
        switch (discountUnit) {
            case "yen":
                return "円";
            case "percent":
                return "%";
        }
    });
};

export default {
    install,
} as PluginObject<void>;
