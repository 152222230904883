import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};

    locales.keys().forEach((key: string) => {
        const matched = key.match(/([a-z0-9-]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
            messages[locale.replace("-", "_")] = locales(key);
            const shortLocale = locale.split("-")[0];
            if (messages[shortLocale] === undefined) {
                messages[shortLocale] = locales(key);
            }
        }
    });
    return messages;
}

const locale: string =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || "en";

export default new VueI18n({
    locale, // set from browser language
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(), // load from file
});
