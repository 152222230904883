import { RouteConfig } from "vue-router";

import BuildingVod from "@/modules/vod/index.vue";
import BuildingVodTop from "@/modules/vod/pages/top.vue";
import BuildingVodFacility from "@/modules/vod/pages/facility.vue";
import BuildingVodVacancy from "@/modules/vod/pages/vacancy.vue";
import BuildingVodMap from "@/modules/vod/pages/map.vue";
import BuildingVodSpot from "@/modules/vod/pages/spot.vue";

const routes: RouteConfig[] = [
    {
        path: "/building/tv/:buildingIdHash",
        component: BuildingVod,
        children: [
            { path: "top", component: BuildingVodTop },
            { path: "facilities/:facilityIdHash", component: BuildingVodFacility },
            { path: "vacancy", component: BuildingVodVacancy },
            { path: "map", component: BuildingVodMap },
            { path: "spot", component: BuildingVodSpot },
        ],
    },
];

export default routes;
