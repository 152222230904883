import * as Vuex from "vuex";

import { HashUtil } from "@/util/hash";

import { Http, Place, Vacancy } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
    fetchPlaceListByWebsiteId,
    fetchHotSpringValuesByPlaceIdHashList,
    fetchVacancyListByPlaceIdHashList,
} from "../api/adapter.api";

export interface Facility {
    facilityId: number;
    sidebarTitle: string;
    title: string;
    information: string;
    introduction: string;
    caution?: string;
    backgroundImageUrl: string;
}

export interface Spot {
    spotId: number;
    name: string;
    introduction: string;
    address: string;
    tel: string;
    backgroundImageUrl: string;
}

interface ModulesVodState {
    areaId: number;
    theme: {
        logoUrl: string;
        mainColor: string;
        subColor: string;
        baseColor: string;
    };
    facilityList: Facility[];
    placeList: Place.Place[];
    hotSpringSettingList: Http.ResponseHotSpringValues[];
    vacancyList: Vacancy.Vacancy[];
    spotList: Spot[];
}

export const state: ModulesVodState = {
    areaId: 6,
    theme: {
        logoUrl: require("@/assets/tv/hanaori/logo_340x340.png"),
        mainColor: "#111111",
        subColor: "#444444",
        baseColor: "#999999",
    },
    facilityList: [
        {
            facilityId: 1,
            sidebarTitle: "フロント",
            title: "フロント",
            information:
                "内線番号: #2　[チェックインタイム Check-in from] 15:00　[チェックアウトタイム Checkout at] 10:00",
            introduction: ``,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/front.jpg"),
        },
        {
            facilityId: 2,
            sidebarTitle: "大浴場『棚湯』",
            title: "大浴場『棚湯』 - 四季の露天風呂",
            information: "[営業時間 Open] 15:00~24:00 / 5:00~10:00　夜・朝の男女入れ替え制",
            introduction: `
                箱根の四季と自然を浴びる開放的な温泉です。　「四季の露天風呂 棚湯(たなゆ)」は、雄大な芦ノ湖の眺望や四季折々に変化する庭園の樹木など、芦ノ湖畔の自然と箱根の名湯を堪能できます。
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/tanayu.jpg"),
        },
        {
            facilityId: 3,
            sidebarTitle: "貸切風呂",
            title: "貸切風呂",
            information: "[営業時間 Open] 15:00~22:00 / 7:00~8:30 [料金] ¥3,000円/40分 ※事前予約制です。",
            introduction: `
                プライベートな温泉時間をお楽しみいただけます。　ご利用のご希望はフロント(#2)までご連絡ください。
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/kashikiri.jpg"),
        },
        {
            facilityId: 4,
            sidebarTitle: "リラクゼーション",
            title: "リラクゼーション",
            information: "内線番号: #8　[営業時間 Open] 15:00~24:00(終了 closed)　ご予約は直接店舗にお申込みください。",
            introduction: `
                ドレナージュコース / ボディケア(手もみ) / アロマソフトコース
            `,
            caution: `
                ※下記に当てはまるお客様は、施術をお控えいただいております。<br/>1.妊娠中の方　2.アルコールの影響下にある方、過度に日焼けをされている方　3.手術後半年未満の方、治療中で医師からの了承を得ていない方、および感染症の疾患に疑いがある方
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/relaxation.jpg"),
        },
        {
            facilityId: 5,
            sidebarTitle: "レストラン",
            title: "ブッフェダイニング「季しかり」",
            information:
                "内線番号: #5　[朝食 Breakfast] 7:00~9:30　[夕食 Dinner] 17:30~21:30　[ラストオーダー last order] 21:00",
            introduction: `
                移りゆく季節の味わいを大切にしたいという思いから、大地の恵みや海の幸を、二十四節気のエッセンスを取り入れた彩りの良い小鉢に仕上げて用意いたします。こだわりの季の味覚、地の滋味、匠なる技を存分にご賞味ください。
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/restaurant.jpg"),
        },
        {
            facilityId: 6,
            sidebarTitle: "水盤テラス・<br/>足湯カウンター",
            title: "水盤テラス・足湯カウンター",
            information: "[営業時間 Open] 7:00~22:00",
            introduction: `
                芦ノ湖の景観がスクリーンのように広がるロビー・ラウンジと開放的な水盤テラス、足湯カウンター。箱根・芦ノ湖畔ならではの澄み切った空気に包まれ、渡る風を素肌に、野鳥のさえずりを耳に感じながらここにしかない時間をお楽しみ頂けます。
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/lobby_lounge.jpg"),
        },
        {
            facilityId: 7,
            sidebarTitle: "カフェ・ショップ",
            title: "カフェ・ショップ",
            information: "[営業時間 Open] 8:00~23:00 (カフェは21:00まで)",
            introduction: `
                セルフカウンターでお好きなお飲み物をオーダーして頂き、お気に入りの場所でお召し上がりいただけるカフェと、箱根伝統工芸寄木細工の品々や旅のスタイルにぴったりな小物類まで取りそろえた、ブティックスタイルのショップです。
            `,
            backgroundImageUrl: require("@/assets/tv/hanaori/facility/cafeshop.jpg"),
        },
    ],
    placeList: [],
    vacancyList: [],
    hotSpringSettingList: [],
    spotList: [
        {
            spotId: 1,
            name: "箱根十七湯",
            introduction:
                "738年(天平10年)に開湯されたといわれている箱根温泉。現在では、十七もの温泉場があるため「箱根十七湯」として、旅行客や地元の方を癒し続けています。泉質や趣も様々な箱根十七湯をこころゆくまで愉しんでみませんか。",
            address: "",
            tel: "",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/17yu.png"),
        },
        {
            spotId: 2,
            name: "カフェレストランLYS",
            introduction:
                "「箱根ラリック美術館」に併設する緑豊かなカフェレストラン。素材を生かしたカジュアルフレンチを楽しめます。",
            address: "神奈川県足柄下郡箱根町仙石原186番1 （箱根ラリック美術館内）",
            tel: "0460-84-2255 ※お席のご予約は承っておりません",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/cafe_restaurant_lys.jpg"),
        },
        {
            spotId: 3,
            name: "サロン・ド・テ ロザージュ",
            introduction:
                "芦ノ湖畔に佇むデザートレストラン。パティシエが作る繊細なスイーツと、紅茶やフレーバーティーをどうぞ。",
            address: "神奈川県足柄下郡箱根町元箱根80 （山のホテル敷地外）",
            tel: "0460-83-6321",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/salon_de_rozazyu.jpg"),
        },
        {
            spotId: 4,
            name: "箱根 自然薯の森 山薬",
            introduction:
                "箱根の自然を感じながら、山菜の大蛇とも呼ばれる『自然薯』を中心に食材にこだわったランチを提供。",
            address: "神奈川県足柄下郡箱根町宮ノ下224",
            tel: "0460-82-1066",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/zinenzyo_no_mori.jpg"),
        },
        {
            spotId: 5,
            name: "ポーラ美術館",
            introduction: "美しい緑の中にとけ込む美術館。西洋絵画や日本画など、幅広いコレクションが展示されています。",
            address: "神奈川県足柄下郡足柄下郡箱根町仙石原小塚山1285",
            tel: "0460-84-2111",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/pola_musium.jpg"),
        },
        {
            spotId: 6,
            name: "箱根 駒ケ岳ロープウェー",
            introduction:
                "全長1,783mを片道約7分で結ぶロープウェー。眼下には芦ノ湖を中心とした箱根の全景を望み、大パノラマが広がります。",
            address: "神奈川県足柄下郡箱根町元箱根139 （箱根園駅）",
            tel: "",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/komagatake_rope_way.jpg"),
        },
        {
            spotId: 7,
            name: "箱根名物雲助だんご 箱根本店",
            introduction:
                "箱根散策の憩いの場としてもぴったりな団子店。白玉団子は餅本来のコシを最大限に引き出したこだわりの味です。",
            address: "神奈川県足柄下郡箱根町箱根81",
            tel: "0460-83-5551",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/unsuke_dango.jpg"),
        },
        {
            spotId: 8,
            name: "大涌谷",
            introduction:
                "箱根火山の最後の爆発によってできた火口の爆発後。今も硫化水素の匂いが立ち込め、自然の荒々しさを感じさせます。",
            address: "神奈川県足柄下郡箱根町仙石原",
            tel: "",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/oowakudani.jpg"),
        },
        {
            spotId: 9,
            name: "大涌谷 くろたまご館",
            introduction:
                "大涌谷名物として知られる黒たまご。大涌谷の地熱と火山ガスで真っ黒に色づいた見た目は、インパクト抜群。",
            address: "神奈川県足柄下郡 箱根町仙石原1251",
            tel: "0460-84-9605",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/oowakudani_kurotamago_kan.jpg"),
        },
        {
            spotId: 10,
            name: "箱根 芦ノ湖遊覧船",
            introduction:
                "芦ノ湖周辺の美しい自然を味わえる芦ノ湖遊覧船。湖上を副風や太陽の光とともに、雄大な景色を堪能できます。",
            address: "神奈川県足柄下郡箱根町元箱根45-3",
            tel: "0460-83-6351",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/ashinoko_yuransen.jpg"),
        },
        {
            spotId: 11,
            name: "箱根ラリック美術館",
            introduction:
                "フランス出身の宝飾とガラス工芸作家ガラス工芸作家・ルネ・ラリックの、独創的で優美な作品をコレクションした美術館。",
            address: "神奈川県足柄下郡箱根町仙石原186番1",
            tel: "0460-84-2255",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/raliyc_musium.jpg"),
        },
        {
            spotId: 12,
            name: "箱根ロープウェイ",
            introduction:
                "箱根登山ケーブルカーと接続するロープウェイ。芦ノ湖や相模湾、富士山など、絶景の空中散歩を楽しめます。",
            address: "神奈川県足柄下郡箱根町元箱根164 （桃源台駅）",
            tel: "",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/hakone_rope_way.jpg"),
        },
        {
            spotId: 13,
            name: "箱根海賊船",
            introduction:
                "箱根屈指の景勝地・芦ノ湖をめぐる海賊船。大自然の景色とテーマパークのような非日常感を一緒に味わえます。",
            address: "足柄下郡箱根町元箱根164 （桃源台港）",
            tel: "0460-84-8618",
            backgroundImageUrl: require("@/assets/tv/hanaori/spot/hakone_kaizokusen.jpg"),
        },
    ],
};

export const mutations: Vuex.MutationTree<ModulesVodState> = {
    SET_PLACE_LIST: (state: ModulesVodState, placeList: Place.Place[]) => {
        state.placeList = placeList;
    },
    SET_HOT_SPRING_SETTING_LIST: (state: ModulesVodState, hotSpringSettingList: Http.ResponseHotSpringValues[]) => {
        state.hotSpringSettingList = hotSpringSettingList;
    },
    SET_VACANCY_LIST: (state: ModulesVodState, vacancyList: Vacancy.Vacancy[]) => {
        state.vacancyList = vacancyList;
    },
};

export const actions: Vuex.ActionTree<ModulesVodState, any> = {
    FETCH_PLACE_LIST_BY_WEBSITE_ID: async ({ commit }: { commit: Vuex.Commit }, websiteId: string): Promise<void> => {
        const website: Http.ResponseGetPlacesInWebsite | undefined = await fetchPlaceListByWebsiteId(websiteId);
        if (website === undefined) {
            commit("SET_PLACE_LIST", []);
            return;
        }
        commit("SET_PLACE_LIST", website.placeList);
    },
    FETCH_HOT_SPRING_SETTING_LIST_BY_WEBSITE_ID: async (
        { commit }: { commit: Vuex.Commit },
        placeIdHashList: string[],
    ): Promise<void> => {
        const hotSpringSetting:
            | Http.ResponseHotSpringValues[]
            | undefined = await fetchHotSpringValuesByPlaceIdHashList(placeIdHashList);
        if (hotSpringSetting === undefined) {
            commit("SET_HOT_SPRING_SETTING_LIST", []);
            return;
        }
        commit("SET_HOT_SPRING_SETTING_LIST", hotSpringSetting);
    },
    FETCH_VACANCY_LIST: async ({ commit }: { commit: Vuex.Commit }, placeIdHashList: string[]): Promise<void> => {
        const vacancyList: Vacancy.Vacancy[] = await fetchVacancyListByPlaceIdHashList(placeIdHashList);
        commit("SET_VACANCY_LIST", vacancyList);
    },
};

export const getters: Vuex.GetterTree<ModulesVodState, any> = {
    placeIdHashList: (state: ModulesVodState) => (): string[] => {
        return state.placeList.map((place: Place.Place) => place.placeIdHash);
    },
    getFacilityByHash: (state: ModulesVodState) => (facilityIdHash: string): Facility | null => {
        const hashUtil: HashUtil = new HashUtil("facility");
        const facilityId: number = hashUtil.idFromHash(facilityIdHash);
        const filteredFacilityList: Facility[] = state.facilityList.filter((facility: Facility) => {
            return facility.facilityId === facilityId;
        });
        if (filteredFacilityList.length === 0) {
            return null;
        }
        return filteredFacilityList[0];
    },
    openingHoursListByPlaceIdHash: (state: ModulesVodState) => (placeIdHash: string): string[] => {
        const hotSpringSetting: Http.ResponseHotSpringValues | undefined = state.hotSpringSettingList.find(
            (hotSpringSetting: Http.ResponseHotSpringValues) => hotSpringSetting.placeIdHash === placeIdHash,
        );
        if (hotSpringSetting === undefined) {
            return [];
        }

        const genderTextMap = { men: "男", women: "女", undefined: "" };
        return hotSpringSetting.openingHourStringList
            .map((openingHourString: Http.HotSpringSettingValue) => {
                const genderText: string | undefined =
                    openingHourString.gender !== undefined ? genderTextMap[openingHourString.gender] : undefined;
                return genderText === undefined
                    ? openingHourString.openingHourString
                    : `${genderText} ${openingHourString.openingHourString}`;
            })
            .reduce((all: string[], current: string) => all.concat(current.split(",")), [])
            .map((openingHoursString: string) => openingHoursString.trim())
            .map((openingHoursString: string) => openingHoursString.replace("-", " - "));
    },
    vacancyByPlaceIdHash: (state: ModulesVodState) => (placeIdHash: string): Vacancy.Vacancy => {
        return (
            state.vacancyList.filter((vacancy: Vacancy.Vacancy) => vacancy.placeIdHash === placeIdHash)[0] || {
                placeIdHash,
                status: {
                    appearance: { backgroundColor: "#ffffff", textColor: "#1ec0b8" },
                    countText: "",
                    state: "open",
                    text: "営業中",
                },
                qticketStatus: { isAcceptable: false, useSizeLimit: false },
            }
        );
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
