import { GetterTree } from "vuex";
import types from "@/store/types";

import { Area, Coupon, Shop, OrganizationLogo, VacancyShop } from "vacan-api";

import { OrganizationState } from "./state";

export const getters: GetterTree<OrganizationState, any> = {
    logoUrl: (state: OrganizationState): string => {
        const landscapeLogo: OrganizationLogo | undefined = state.logoList
            .filter((organizationLogo: OrganizationLogo) => organizationLogo.shape === "landscape")
            .shift();
        return landscapeLogo ? landscapeLogo.url : "";
    },
    areaByAreaId: (state: OrganizationState) => (areaId: number): Area | undefined => {
        return state.areaList.filter((area: Area) => area.areaId === areaId).shift();
    },
    shopListByAreaId: (state: OrganizationState) => (areaId: number): Shop[] => {
        return state.shopList.filter((shop: Shop) => shop.areaId === areaId);
    },
    floorList: (state: OrganizationState): string[] => {
        return state.shopList
            .map((shop: Shop) => shop.floor || undefined)
            .filter((floor: string | undefined): floor is string => floor !== undefined)
            .filter((floor: string, index: number, array: string[]) => array.indexOf(floor) === index);
    },
    vacancyShopByShopId: (state: OrganizationState) => (shopId: number): VacancyShop => {
        return (
            state.vacancyShopList.filter((vacancyShop: VacancyShop) => vacancyShop.shopId === shopId)[0] || {
                shopId: shopId,
                usedPercentage: 0,
                waitingSeconds: 0,
                inputSource: "manual",
                vacancyState: "vacant",
                unixtime: new Date().valueOf() / 1000,
            }
        );
    },
    isCouponExistsByShopId: (state: OrganizationState) => (shopId: number): boolean => {
        return state.couponList.filter((coupon: Coupon) => coupon.shopId === shopId).length > 0;
    },
};
