import Vue from "vue";

import PluginFilterCoupon from "@/plugin/filter/coupon";
import PluginFilterImageCollection from "@/plugin/filter/imageCollection";
import PluginFilterSchedule from "@/plugin/filter/schedule";
import "@/plugin/validator";

Vue.use(PluginFilterCoupon);
Vue.use(PluginFilterImageCollection);
Vue.use(PluginFilterSchedule);
