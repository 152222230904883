var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"views-cavi-navigation"},[_c('cavi-header',{staticClass:"header",attrs:{"label":_vm.errorStatus === undefined ? '目的地' : '接続エラー',"canBack":true}}),(_vm.errorStatus === undefined)?[(_vm.failedCommand !== undefined)?_c('cavi-error-header',{on:{"refresh":_vm.onRefresh}}):_vm._e(),_c('nav',{staticClass:"floors"},[(_vm.mapList.length <= 1)?[_c('div',{staticClass:"floor-item start",class:{ current: this.startMap.mapId === _vm.currentMap.mapId }},[_vm._v(" 現在地"),_c('br'),_vm._v(_vm._s(this.startMap.mapName)+" ")]),_c('div',{staticClass:"floor-item destination",class:{ current: this.destination.mapId === _vm.currentMap.mapId }},[_vm._v(" 目的地"),_c('br'),_vm._v(_vm._s(this.destination !== undefined ? this.destination.mapName : undefined)+" "),_c('img',{staticClass:"right-arrow",attrs:{"src":require("@/assets/cavi/arrow-right.svg")}})])]:_vm._l((_vm.mapList),function(map,mapIndex){return _c('button',{key:map.mapId,staticClass:"floor-item",class:{
            short: _vm.mapList.length > 2,
            current: map.mapId === _vm.currentMap.mapId,
            start: map.mapId === _vm.startMap.mapId,
            destination: map.mapId === _vm.destination.mapId,
          },on:{"click":function($event){return _vm.onSelectTab(mapIndex)}}},[_vm._v(" "+_vm._s(mapIndex === 0 ? "現在地" : mapIndex === _vm.mapList.length - 1 ? "目的地" : "経由地")),_c('br'),_vm._v(_vm._s(map.mapName)+" "),(mapIndex !== 0)?_c('img',{staticClass:"right-arrow",attrs:{"src":require("@/assets/cavi/arrow-right.svg")}}):_vm._e()])})],2),_c('div',{staticClass:"contents",class:{ error: _vm.failedCommand !== undefined }},[_c('cavi-map',{attrs:{"mapImage":_vm.currentMap.mapImage,"start":_vm.currentMap.mapId === _vm.startMap.mapId
            ? { x: _vm.startMapPoint.pointX, y: _vm.startMapPoint.pointY, direction: _vm.startMapPoint.qrDirection }
            : undefined,"arrival":_vm.currentMapPoint !== undefined && _vm.currentMap.mapId !== _vm.startMap.mapId
            ? { x: _vm.currentMapPoint.pointX, y: _vm.currentMapPoint.pointY, direction: _vm.currentDirection }
            : undefined,"destination":_vm.currentMap.mapId === _vm.destination.mapId
            ? { x: _vm.destination.mapPoint.pointX, y: _vm.destination.mapPoint.pointY }
            : undefined,"connectorList":_vm.connectorList,"boundingPointList":_vm.boundingPointList},on:{"selectPin":_vm.onSelectPin}}),(_vm.showDescription)?_c('div',{staticClass:"pin-description"},[_c('img',{staticClass:"connector-icon",attrs:{"src":require("@/assets/cavi/pins/connector-pin.svg")}}),_vm._m(0),_c('i',{staticClass:"material-icons",on:{"click":function($event){_vm.closeDescriptionByUser = true}}},[_vm._v("close")])]):_vm._e()],1),_c('div',{staticClass:"footer"},[_vm._m(1),(_vm.questionnairUrl)?_c('a',{staticClass:"questionnair-link",attrs:{"href":_vm.questionnairUrl,"target":"_blank","rel":"noopener noreferrer"},on:{"click":_vm.onClickQuestionnairLink}},[_vm._v(" アンケートへ "),_c('img',{attrs:{"src":require("@/assets/cavi/external-link.svg")}})]):_vm._e()])]:[_c('cavi-error-header',{on:{"refresh":function($event){return _vm.$router.go(0)}}}),_c('cavi-error',{attrs:{"status":_vm.errorStatus}})]],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"description-text"},[_vm._v(" ピンをタップすると、次の"),_c('span',{staticClass:"highlight-arrival"},[_vm._v("到着地")]),_vm._v("が分かります ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"terms-link"},[_c('a',{attrs:{"href":"/cavi/terms-of-service.html","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" 利用規約 ")]),_vm._v(" ・ "),_c('a',{attrs:{"href":"https://corp.vacan.com/guide/policy","target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" プライバシーポリシー ")])])}]

export { render, staticRenderFns }