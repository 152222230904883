interface HashSeeds {
    area: string;
    building: string;
    coupon: string;
    facility: string;
    line: string;
    monitor: string;
    organization: string;
    shop: string;
    place: string;
}
export type HashType = keyof HashSeeds;

import { default as Hashids } from "hashids";

export class HashUtil {
    private type: HashType;
    private seeds: HashSeeds = {
        area: "vacan/areaId",
        building: "vacan/buildingId", // DEPRICATED this shouldnt be used
        coupon: "vacan/couponId",
        facility: "vacan/facilityId",
        line: "vacan/lineId",
        monitor: "vacan/monitorId",
        organization: "MUpZ9XYfLTngc8GQ",
        shop: "vacan/shopId",
        // for 3rd gen migration
        place: "place/placeId",
    };

    private hashDegits: number = 8;

    constructor(type: HashType) {
        this.type = type;
    }

    public hashFromId(id: number): string {
        const obj = new Hashids(this.seeds[this.type], this.hashDegits);
        return obj.encode(id);
    }

    public idFromHash(hash: string): number {
        const hashids = new Hashids(this.seeds[this.type], this.hashDegits);
        return hashids.decode(hash).shift() || 0;
    }
}
