import Vue from "vue";
import router from "@/router";
import VueGtag from "vue-gtag";
import VueAnalytics from "vue-analytics";

//GA4 measurement ID: G-BR01RW305E
Vue.use(VueGtag, {
    config: {
        id: "G-BR01RW305E",
        params: {send_page_view: true,}
    },
} );

Vue.use(VueAnalytics, { //need this for Cavi
    id: "UA-109281152-1",
    router,
});
