export function save(key: string, value: any): boolean {
    try {
        sessionStorage.setItem(`cavi/${key}`, JSON.stringify(value));
        return true;
    } catch {
        return false;
    }
}

export function load(key: string): any {
    const storedItem = sessionStorage.getItem(`cavi/${key}`);
    return storedItem !== null ? JSON.parse(storedItem) : undefined;
}

export function clear(key: string): void {
    sessionStorage.removeItem(`cavi/${key}`);
}
