import { ActionTree, MutationTree, GetterTree, Commit } from "vuex";
import types from "@/store/types";

import { Area, Coupon, Organization, Shop, VacancyShop, ShopTranslation } from "vacan-api";

import { OrganizationState } from "./state";

export const mutations: MutationTree<OrganizationState> = {
    [types.organization.mutations.SET_ORGANIZATION]: (state: OrganizationState, organization: Organization) => {
        state.name = organization.name;
        state.organizationId = organization.organizationId;
        state.address = organization.address;
        state.tel = organization.tel;
        state.logoList = organization.logoList;
    },
    [types.organization.mutations.SET_AREA_LIST]: (state: OrganizationState, areaList: Area[]) => {
        state.areaList = areaList;
    },
    [types.organization.mutations.SET_COUPON_LIST]: (state: OrganizationState, couponList: Coupon[]) => {
        state.couponList = couponList;
    },
    [types.organization.mutations.SET_SHOP_LIST]: (state: OrganizationState, shopList: Shop[]) => {
        state.shopList = shopList;
        state.shopList.forEach((shop: Shop) => {
            shop.localizedList.sort((a: ShopTranslation, b: ShopTranslation) => (a.isPrimary ? -1 : 0));
        });
    },
    [types.organization.mutations.SET_VACANCY_SHOP_LIST]: (
        state: OrganizationState,
        vacancyShopList: VacancyShop[],
    ) => {
        state.vacancyShopList = vacancyShopList;
    },
    [types.organization.mutations.INCREMENT_ANIMATION_IMAGE_COUNTER]: (state: OrganizationState) => {
        state.animationImageCounter++;
        if (state.animationImageCounter >= Number.MAX_SAFE_INTEGER) {
            state.animationImageCounter = 0;
        }
    },
    [types.organization.mutations.INCREMENT_ANIMATION_LOCALIZE_COUNTER]: (state: OrganizationState) => {
        state.animationLocalizeCounter++;
        if (state.animationLocalizeCounter >= Number.MAX_SAFE_INTEGER) {
            state.animationLocalizeCounter = 0;
        }
    },
};
