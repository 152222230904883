import { AxiosResponse, AxiosError } from "axios";
import $http from "./http";
import { VacanserviceAdapterApi } from "@vacancorp/vacanservice.adapter.api.vacanservice.com";
import { Vacancy } from "noline";
import I18n from "@/i18n";
import store from "@/store";
import types from "@/modules/noline/store/types";

const ConnectionError = { message: "connection error", name: "" };

export function fetchVacancyListByPlaceIdHashList(placeIdHashList: string): Promise<Vacancy[]> {
    const now = Date.now();
    const baseUrl = `/v1/places/${placeIdHashList}/vacancies`;

    return $http
        .get(baseUrl, {
            params: { unixtime: now, language: I18n.locale },
        })
        .then((response: AxiosResponse<VacanserviceAdapterApi.ResponseGetVacancyWithCapacity[]>) => {
            store.commit(types.mutations.REMOVE_REQUEST_ERROR, baseUrl);
            return response.data.map((backendVacancy: VacanserviceAdapterApi.ResponseGetVacancyWithCapacity) => ({
                ...backendVacancy,
                lastUpdated: now,
            }));
        })
        .catch((error: AxiosError) => {
            store.commit(types.mutations.SET_REQUEST_ERROR, baseUrl);
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchPlaceListByPlaceGroupIdHashList(
    placeGroupIdHashList: string,
): Promise<VacanserviceAdapterApi.ResponseGetPlace[]> {
    const baseUrl = `/v1/place-groups/${placeGroupIdHashList}/places`;

    return $http
        .get(baseUrl, { params: { language: I18n.locale } })
        .then((response: AxiosResponse) => {
            store.commit(types.mutations.REMOVE_REQUEST_ERROR, baseUrl);
            return response.data;
        })
        .catch((error: AxiosError) => {
            store.commit(types.mutations.SET_REQUEST_ERROR, baseUrl);
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchTicketConfigListByPlaceIdHashList(
    placeIdHashList: string,
): Promise<VacanserviceAdapterApi.ResponseGetTicketConfig[]> {
    const now = Date.now();
    const baseUrl = `/v1/places/${placeIdHashList}/ticket-configs`;

    return $http
        .get(baseUrl, {
            params: { unixtime: now, language: I18n.locale },
        })
        .then((response: AxiosResponse<VacanserviceAdapterApi.ResponseGetTicketConfig[]>) => {
            store.commit(types.mutations.REMOVE_REQUEST_ERROR, baseUrl);
            return response.data;
        })
        .catch((error: AxiosError) => {
            store.commit(types.mutations.SET_REQUEST_ERROR, baseUrl);
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}
export function fetchNolineAttributesByPlaceIdHash(
    placeIdHash: string,
): Promise<VacanserviceAdapterApi.ResponseGetNolineAttributes> {
    const baseUrl = `/v1/places/${placeIdHash}/noline-attributes`;

    return $http
        .get(baseUrl, { params: { language: I18n.locale } })
        .then((response: AxiosResponse) => {
            store.commit(types.mutations.REMOVE_REQUEST_ERROR, baseUrl);
            return response.data;
        })
        .catch((error: AxiosError) => {
            store.commit(types.mutations.SET_REQUEST_ERROR, baseUrl);
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function postPreRequest(
    placeIdHash: string,
    body: VacanserviceAdapterApi.RequestsPostPreRequestsBody,
): Promise<boolean> {
    return $http
        .post(`/v1/places/${placeIdHash}/pre-requests`, body)
        .then((response: AxiosResponse) => {
            if (response.status === 201) {
                return true;
            }
            return false;
        })
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function postNotificationPreRequest(ticketIdHash: string, email: string, activateUrl: string): Promise<boolean> {
    return $http
        .post(`/v1/tickets/${ticketIdHash}/notifications/pre-request`, { email, activateUrl })
        .then((response: AxiosResponse) => {
            return response.status === 201;
        })
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function postTickets(requestIdHash: string): Promise<VacanserviceAdapterApi.ResponsePostTicket | undefined> {
    return $http
        .post(`/v1/tickets`, { requestIdHash })
        .then((response: AxiosResponse) => {
            if (response.status === 201) {
                return response.data;
            }
            return undefined;
        })
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function getTicket(ticketIdHash: string): Promise<VacanserviceAdapterApi.ResponseGetTicket> {
    const baseUrl = `/v1/tickets/${ticketIdHash}`;

    return $http
        .get(baseUrl, { params: { language: I18n.locale } })
        .then((response: AxiosResponse) => {
            store.commit(types.mutations.REMOVE_REQUEST_ERROR, baseUrl);
            return response.data;
        })
        .catch((error: AxiosError) => {
            store.commit(types.mutations.SET_REQUEST_ERROR, baseUrl);
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function cancelTicket(ticketIdHash: string): Promise<VacanserviceAdapterApi.ResponseGetTicket> {
    return $http
        .put(`/v1/tickets/${ticketIdHash}`, { operationType: "cancel" }, { params: I18n.locale })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function verifyPhoneNumber(
    activationHash: string,
): Promise<VacanserviceAdapterApi.ResponsePutNotificationActivation> {
    return $http
        .put(`/v1/tickets/notifications/activations`, { activationHash })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

// NOTE: verifyPhoneNumberと実装は同じだが、実証実験・移行期間中に独立して変更しやすいよう別関数とする
export function verifyEmail(activationHash: string): Promise<VacanserviceAdapterApi.ResponsePutNotificationActivation> {
    return $http
        .put(`/v1/tickets/notifications/activations`, { activationHash })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data : ConnectionError));
}

export function deleteEmail(ticketIdHash: string): Promise<boolean> {
    return $http
        .delete(`/v1/tickets/${ticketIdHash}/notifications`)
        .then((response: AxiosResponse) => {
            return response.status === 204;
        })
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchTicketConfigByTicketIdHash(ticketIdHash: string): Promise<VacanserviceAdapterApi.TicketConfig> {
    return $http
        .get(`/v1/tickets/${ticketIdHash}/configs`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchAnnouncementByPlaceIdHash(
    placeIdHash: string,
): Promise<VacanserviceAdapterApi.ResponseGetAnnouncements> {
    return $http
        .get(`/v1/places/${placeIdHash}/announcements`, { params: { language: I18n.locale } })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}
