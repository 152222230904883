import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";
import { SignageAdapterApi } from "@vacancorp/signage.adapter.api.vacanservice.com";

const $http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SIGNAGE_ADAPTER_API_BASE_URL,
    timeout: 10000,
});

export const fetchPlaceListByPlaceIdHashList = async (
    placeIdHashList: string[],
): Promise<SignageAdapterApi.ResponseGetPlace[]> => {
    return await $http
        .get(`/places/${placeIdHashList}`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: Error) => Promise.reject(error));
};

export const fetchDisplayVacancyByPlaceIdHashList = async (
    placeIdHashList: string[],
): Promise<SignageAdapterApi.ResponseGetDisplayVacancy[]> => {
    return await $http
        .get(`/places/${placeIdHashList}/vacancy`)
        .then((response: AxiosResponse) => response.data)
        .catch((error: Error) => Promise.reject(error));
};
