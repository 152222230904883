import { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import $http from "@/api/http";

import { Shop } from "vacan-api";

export default class {
    public static async getShopByShopId(shopId: number): Promise<Shop[]> {
        return await $http
            .get(`/shops/${shopId}`)
            .then((response: AxiosResponse) => response.data.items as Shop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getShopListByOrganizationId(organizationId: number, areaIdList?: number[]): Promise<Shop[]> {
        // const isJoinus: boolean = organizationId === 3;
        const isSortByFloor: boolean =
            organizationId === 3 ||
            organizationId === 18 ||
            organizationId === 19 ||
            organizationId === 33 ||
            organizationId === 34 ||
            organizationId === 54;

        return await $http
            .get(`/organizations/${organizationId}/shops${isSortByFloor ? "?sort=display-order" : ""}`, {
                params: areaIdList ? { areas: areaIdList.join(",") } : undefined,
            })
            .then((response: AxiosResponse) => response.data.items as Shop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getShopListByUserId(userId: number): Promise<Shop[]> {
        return await $http
            .get(`/users/${userId}/shops`)
            .then((response: AxiosResponse) => response.data.items as Shop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async updateShop(shop: Shop): Promise<Shop[]> {
        return await $http
            .put(`/shops/${shop.shopId}`, {
                organizationId: shop.organizationId,
                areaId: shop.areaId,
                floor: shop.floor,
                address: shop.address,
                tel: shop.tel,
                mapUrl: shop.mapUrl,
                plan: shop.plan,
                inputMethod: shop.inputMethod,
            })
            .then((response: AxiosResponse) => response.data.items as Shop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }
}
