import { render, staticRenderFns } from "./entry-email-invalid-activation.vue?vue&type=template&id=2a621804&scoped=true&"
import script from "./entry-email-invalid-activation.vue?vue&type=script&lang=ts&"
export * from "./entry-email-invalid-activation.vue?vue&type=script&lang=ts&"
import style0 from "./entry-email-invalid-activation.vue?vue&type=style&index=0&id=2a621804&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a621804",
  null
  
)

export default component.exports