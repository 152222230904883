import { ActionTree, Commit } from "vuex";
import types from "@/store/types";

import { Organization, Shop } from "vacan-api";

import AreaApi from "@/api/area";
import CouponApi from "@/api/coupon";
import ShopApi from "@/api/shop";
import OrganizationApi from "@/api/organization";
import VacancyApi from "@/api/vacancy";

import { HashUtil } from "@/util/hash";

import { OrganizationState } from "./state";

let idForVacancyShop: number;
let idForAnimationImageCounter: number;
let idForAnimationLocalizeCounter: number;

export const actions: ActionTree<OrganizationState, any> = {
    [types.organization.actions.SETUP]: async (
        { commit }: { commit: Commit },
        organizationHash: string,
    ): Promise<void> => {
        const hashUtil: HashUtil = new HashUtil("organization");
        const organizationId: number = hashUtil.idFromHash(organizationHash);
        const organization: Organization | undefined = await OrganizationApi.getOrganizationByOrganizationId(
            organizationId,
        );
        if (organization === undefined) {
            return;
        }
        commit(types.organization.mutations.SET_ORGANIZATION, organization);
        commit(types.organization.mutations.SET_AREA_LIST, await AreaApi.getAreaListByOrganizationId(organizationId));
        commit(
            types.organization.mutations.SET_COUPON_LIST,
            await CouponApi.getActiveCouponListByOrganizationId(organizationId),
        );
        commit(types.organization.mutations.SET_SHOP_LIST, await ShopApi.getShopListByOrganizationId(organizationId));
        commit(
            types.organization.mutations.SET_VACANCY_SHOP_LIST,
            await VacancyApi.getVacancyByOrganizationIdAndAreaIdList(organizationId),
        );
    },
    [types.organization.actions.LOOP]: async ({
        commit,
        state,
    }: {
        commit: Commit;
        state: OrganizationState;
    }): Promise<void> => {
        idForVacancyShop = window.setInterval(async () => {
            commit(
                types.organization.mutations.SET_VACANCY_SHOP_LIST,
                await VacancyApi.getVacancyByOrganizationIdAndAreaIdList(state.organizationId),
            );
        }, 10 * 1000);

        idForAnimationImageCounter = window.setInterval(
            () => commit(types.organization.mutations.INCREMENT_ANIMATION_IMAGE_COUNTER),
            5 * 1000,
        );
        idForAnimationLocalizeCounter = window.setInterval(
            () => commit(types.organization.mutations.INCREMENT_ANIMATION_LOCALIZE_COUNTER),
            10 * 1000,
        );
    },
    [types.organization.actions.TEARDOWN]: (): void => {
        window.clearInterval(idForVacancyShop);
        window.clearInterval(idForAnimationImageCounter);
        window.clearInterval(idForAnimationLocalizeCounter);
    },
};
