import { Area, Coupon, Organization, Shop, VacancyShop } from "vacan-api";

export interface OrganizationState extends Organization {
    areaList: Area[];
    couponList: Coupon[];
    shopList: Shop[];
    vacancyShopList: VacancyShop[];
    // animations
    animationImageCounter: number;
    animationLocalizeCounter: number;
}

export const state: OrganizationState = {
    organizationId: 0,
    name: "",
    address: "",
    tel: "",
    logoList: [],
    areaList: [],
    couponList: [],
    shopList: [],
    vacancyShopList: [],
    // animations
    animationImageCounter: 0,
    animationLocalizeCounter: 0,
};
