import { AxiosResponse, AxiosError } from "axios";
import { ActionTree, MutationTree, GetterTree, Commit } from "vuex";
import types from "@/store/types";

import { Area, Coupon, Shop, ShopTranslation, Organization, VacancyShop } from "vacan-api";

import AreaApi from "@/api/area";
import CouponApi from "@/api/coupon";
import ShopApi from "@/api/shop";
import OrganizationApi from "@/api/organization";
import VacancyApi from "@/api/vacancy";

export interface ShopState {
    shop: Shop | undefined;
    organization: Organization | undefined;
    area: Area | undefined;
    couponList: Coupon[];
    vacancy: VacancyShop | undefined;
}

export const state: ShopState = {
    shop: undefined,
    organization: undefined,
    area: undefined,
    couponList: [],
    vacancy: undefined,
};

export const mutations: MutationTree<ShopState> = {
    [types.shop.mutations.SET_SHOP]: (state: ShopState, shop: Shop) => {
        state.shop = shop;
        state.shop.localizedList.sort((a: ShopTranslation, b: ShopTranslation) => (a.isPrimary ? -1 : 0));
    },
    [types.shop.mutations.SET_ORGANIZATION]: (state: ShopState, organization: Organization) => {
        state.organization = organization;
    },
    [types.shop.mutations.SET_AREA]: (state: ShopState, area: Area) => {
        state.area = area;
    },
    [types.shop.mutations.SET_COUPON_LIST]: (state: ShopState, couponList: Coupon[]) => {
        state.couponList = couponList;
    },
    [types.shop.mutations.SET_VACANCY]: (state: ShopState, vacancy: VacancyShop) => {
        state.vacancy = vacancy;
    },
};

export const actions: ActionTree<ShopState, any> = {
    [types.shop.actions.SETUP]: async ({ commit }: { commit: Commit }, shopId: number): Promise<void> => {
        const shopList: Shop[] = await ShopApi.getShopByShopId(shopId);
        const shop: Shop | undefined = shopList.shift();
        if (shop === undefined) {
            return;
        }
        commit(types.shop.mutations.SET_SHOP, shop);

        if (shop.organizationId) {
            const organization: Organization | undefined = await OrganizationApi.getOrganizationByOrganizationId(
                shop.organizationId,
            );
            commit(types.shop.mutations.SET_ORGANIZATION, organization);
            const areaList: Area[] = await AreaApi.getAreaListByOrganizationId(shop.organizationId);
            const area: Area | undefined = areaList.filter((area: Area) => area.areaId === shop.areaId).shift();
            commit(types.shop.mutations.SET_AREA, area);
        }

        const couponList: Coupon[] = await CouponApi.getActiveCouponListByShopId(shopId);
        commit(types.shop.mutations.SET_COUPON_LIST, couponList);

        const vacancy: VacancyShop[] = await VacancyApi.getVacancyByShopId(shopId);
        commit(types.shop.mutations.SET_VACANCY, vacancy[0]);
    },
};

export const getters: GetterTree<ShopState, any> = {
    primaryName: (state: ShopState): string => {
        const shop: Shop | undefined = state.shop;
        if (shop === undefined) {
            return "";
        }
        const primary: ShopTranslation | undefined = shop.localizedList
            .filter((translation: ShopTranslation) => translation.isPrimary)
            .shift();
        return primary ? `${primary.nameLine1} ${primary.nameLine2}` : "";
    },
    isCouponExists: (state: ShopState) => (): boolean => {
        return state.couponList.length > 0;
    },
    isMapExists: (state: ShopState) => (): boolean => {
        return (
            state.shop !== undefined &&
            (state.shop.mapUrl !== null ||
                state.shop.address !== null ||
                (state.area !== undefined && state.area.mapUrl !== null))
        );
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
