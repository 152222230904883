import { AxiosResponse, AxiosError } from "axios";
import $http from "./http";
import { VacanserviceAdapterApi } from "@vacancorp/vacanservice.adapter.api.vacanservice.com";
import I18n from "@/i18n";

const ConnectionError = { message: "connection error", name: "" };

// export function fetchMapGroupByAlias(alias: string): Promise<VacanserviceAdapterApi.ResponseGetMapGroup> {
export function fetchMapGroupByAlias(alias: string): Promise<{ mapGroupId: number }> {
    const baseUrl: string = `/v1/map-groups/${alias}`;
    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchMapPointListWithKeyword(
    keyword: string | undefined,
    mapGroupId?: number,
): Promise<VacanserviceAdapterApi.ResponseSearchMapPointListByKeyword> {
    const baseUrl: string = `/v1/maps/map-points`;
    return $http
        .get(baseUrl, {
            params: {
                keyword,
                mapGroupId,
            },
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchMapPoint(mapPointId: number): Promise<VacanserviceAdapterApi.ResponseGetMapPointWithMap> {
    const baseUrl: string = `/v1/maps/map-points/${mapPointId}`;

    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchServiceTypeListByMapGroupId(mapGroupId: number): Promise<VacanserviceAdapterApi.ResponseGetServiceType[]> {
    const baseUrl = `/v1/map-groups/${mapGroupId}/service-types`;

    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError))
}

export function fetchPlaceList(
    mapId: number,
    serviceType: string,
): Promise<VacanserviceAdapterApi.ResponseGetPlace[]> {
    const baseUrl: string = "/v1/maps/places";
    const params: Record<string, any> = { mapId, serviceType };

    return $http
        .get(baseUrl, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchVacancyList(placeIdHashList: string): Promise<VacanserviceAdapterApi.ResponseGetVacancy[]> {
    const now = Date.now();
    const baseUrl = `/v1/maps/places/${placeIdHashList}/vacancies`;

    return $http
        .get(baseUrl, {
            params: { unixtime: now, language: I18n.locale },
        })
        .then((response: AxiosResponse<VacanserviceAdapterApi.ResponseGetVacancy[]>) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchDestinationMapPoint(
    mapId: number,
    placeIdHash: string,
): Promise<VacanserviceAdapterApi.ResponseGetMapPointWithMap> {
    const baseUrl = `/v1/maps/places/${placeIdHash}/map-points`;
    const params: Record<string, any> = { mapId };

    return $http
        .get(baseUrl, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchRouteMapList(fromMapId: number, toMapId: number): Promise<number[]> {
    const baseUrl = "/v1/maps/route-maps";
    const params: Record<string, any> = { fromMapId, toMapId };

    return $http
        .get(baseUrl, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchMapPointLinkList(
    fromMapId: number,
    toMapId: number,
): Promise<VacanserviceAdapterApi.ResponseGetMapPointLink[]> {
    const baseUrl = "/v1/map-point-links";
    const params: Record<string, any> = { fromMapId, toMapId };

    return $http
        .get(baseUrl, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}

export function fetchMapList(mapIdList: number[]): Promise<VacanserviceAdapterApi.ResponseGetMap[]> {
    if (mapIdList.length === 0) {
        return Promise.resolve([]);
    }

    const baseUrl = `/v1/maps/${mapIdList.join(",")}`;

    return $http
        .get(baseUrl)
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response : ConnectionError));
}
