import types from "./types";
import { ActionContext } from "vuex";
import { fetchMapPoint } from "../api/vacanservice.adapter.api";
import { Map, MapPoint } from "cavi";

export type CaviState = {
    currentMap?: Map;
    currentMapPoint?: MapPoint;
    hasErrorInFetchMapPoint?: number;
};

const state: CaviState = {};

const getters = {
    [types.getters.GET_CURRENT_MAP](state: CaviState) {
        return state.currentMap;
    },
    [types.getters.GET_CURRENT_MAP_POINT](state: CaviState) {
        return state.currentMapPoint;
    },
    [types.getters.GET_HAS_ERROR_IN_FETCH_MAP_POINT](state: CaviState) {
        return state.hasErrorInFetchMapPoint;
    },
};

const mutations = {
    [types.mutations.SET_CURRENT_MAP](state: CaviState, currentMap: Map) {
        state.currentMap = currentMap;
    },
    [types.mutations.SET_CURRENT_MAP_POINT](state: CaviState, currentMapPoint: MapPoint) {
        state.currentMapPoint = currentMapPoint;
    },
    [types.mutations.SET_HAS_ERROR_IN_FETCH_MAP_POINT](state: CaviState, hasError: number) {
        state.hasErrorInFetchMapPoint = hasError;
    },
};

const actions = {
    async [types.actions.FETCH_CURRENT_MAP_POINT]({ commit }: ActionContext<CaviState, any>, mapPointId: number) {
        try {
            const mapPoint = await fetchMapPoint(mapPointId);

            commit(types.mutations.SET_CURRENT_MAP, mapPoint);
            commit(types.mutations.SET_CURRENT_MAP_POINT, mapPoint.mapPoint);
        } catch (error) {
            commit(types.mutations.SET_HAS_ERROR_IN_FETCH_MAP_POINT, error.status || 0);
        }
    },
};

export default { state, getters, mutations, actions };
