import { AxiosResponse, AxiosError } from "axios";
import $http from "@/api/http";

import { VacancyLine, VacancySeat, VacancyShop, VacancyTable, VacancyState } from "vacan-api";

export default class {
    public static async getVacancyByOrganizationIdAndAreaIdList(
        organizationId: number,
        areaIdList?: number[],
    ): Promise<VacancyShop[]> {
        return await $http
            .get(`/organizations/${organizationId}/vacancy`, {
                params: areaIdList ? { areas: areaIdList.join(",") } : undefined,
            })
            .then((response: AxiosResponse) => response.data.items as VacancyShop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getVacancyByShopId(shopId: number): Promise<VacancyShop[]> {
        return await $http
            .get(`/shops/${shopId}/vacancy`)
            .then((response: AxiosResponse) => response.data.items as VacancyShop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getVacancyLineByShopId(shopId: number): Promise<VacancyLine[]> {
        return await $http
            .get(`/shops/${shopId}/vacancy/lines`)
            .then((response: AxiosResponse) => response.data.items as VacancyLine[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async getVacancySeatByShopId(shopId: number): Promise<VacancySeat[]> {
        return await $http
            .get(`/shops/${shopId}/vacancy/seats`)
            .then((response: AxiosResponse) => response.data.items as VacancySeat[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async postVacancyShops(
        shopId: number,
        vacancyState: VacancyState,
        waitingSeconds: number,
        unixtime: number = new Date().valueOf() / 1000,
    ): Promise<VacancyShop[]> {
        return await $http
            .post(`/shops/${shopId}/vacancy/shops`, { vacancyState, waitingSeconds, unixtime })
            .then((response: AxiosResponse) => response.data.items as VacancyShop[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static async postVacancyTables(shopId: number, vacancyTableList: VacancyTable[]): Promise<VacancyTable[]> {
        return await $http
            .post(`shops/${shopId}/vacancy/tables`, { vacancyTableList })
            .then((response: AxiosResponse) => response.data.items as VacancyTable[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }
}
