import { AxiosResponse, AxiosError } from "axios";
import { $http } from "./http";

import { Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

const ConnectionError = { message: "connection error", name: "" };
const locale = "ja";

export function fetchPlaceListByWebsiteId(websiteId: string): Promise<Http.ResponseGetPlacesInWebsite | undefined> {
    const namespace = "enterprise";
    return $http
        .get(`/api/v1/websites/${websiteId}`, { params: { namespace, language: locale } })
        .then((response: AxiosResponse) => (response.status === 204 ? undefined : response.data))
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchHotSpringValuesByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<Http.ResponseHotSpringValues[] | undefined> {
    const namespace = "enterprise";
    return $http
        .get(`/api/v1/places/${placeIdHashList.join(",")}/hotspring/settings`, {
            params: { namespace, language: locale },
        })
        .then((response: AxiosResponse) => (response.status === 204 ? undefined : response.data))
        .catch((error: AxiosError) => {
            Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchVacancyListByPlaceIdHashList(placeIdHashList: string[]): Promise<Http.ResponseGetPlacesVacancies> {
    const params: Record<string, string> = { language: locale };

    return $http
        .get(`/api/v1/places/${placeIdHashList.join(",")}/vacancies`, { params })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}
