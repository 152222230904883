import { RouteConfig } from "vue-router";

import Index from "@/modules/cavi/index.vue";
import Current from "@/modules/cavi/views/current.vue";
import Category from "@/modules/cavi/views/category.vue";
import Places from "@/modules/cavi/views/places.vue";
import Navigation from "@/modules/cavi/views/navigation.vue";
import Search from "@/modules/cavi/views/search.vue";

import store from "@/store";
import types from "@/modules/cavi/store/types";

const routes: RouteConfig[] = [
    {
        path: "/cavi",
        component: Index,
        beforeEnter: async (from, _to, next) => {
            if (from.params.mapPointId === undefined || from.params.mapPointId.length === 0) return next();
            if (store.state.cavi.currentMap === undefined) {
                await store.dispatch(types.actions.FETCH_CURRENT_MAP_POINT, parseInt(from.params.mapPointId, 10));
            }

            next();
            return;
        },
        children: [
            {
                path: "search/:alias",
                name: "cavi/search",
                component: Search,
                props: true,
            },
            {
                path: "search",
                component: Search,
                props: { alias: "joinus-yokohama" }
            },
            {
                path: ":mapPointId(\\d+)",
                name: "cavi/top",
                component: Current,
                props: route => Object.assign({}, route.params, route.query),
            },
            {
                path: ":mapPointId(\\d+)/category",
                name: "cavi/category",
                component: Category,
                props: route => Object.assign({}, route.params, route.query),
            },
            {
                path: ":mapPointId(\\d+)/places",
                name: "cavi/places",
                component: Places,
                props: route =>
                    Object.assign({}, route.params, {
                        ...route.query,
                    }),
                beforeEnter: (from, to, next) => {
                    if (!from.query.serviceType) {
                        next({ name: "cavi/category", params: from.params, query: from.query, replace: true });
                    }
                    next();
                },
            },
            {
                path: ":mapPointId(\\d+)/navigation/:placeIdHash",
                name: "cavi/navigation",
                component: Navigation,
                props: route => Object.assign({}, route.params, route.query),
            },
        ],
    },
];

export default routes;
