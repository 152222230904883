function save(key: string, value: any): boolean {
    try {
        localStorage.setItem(`noline/${key}`, JSON.stringify(value));
        return true;
    } catch {
        return false;
    }
}

function load(key: string): any {
    const storedItem = localStorage.getItem(`noline/${key}`);
    return storedItem !== null ? JSON.parse(storedItem) : undefined;
}

function clear(key: string): void {
    localStorage.removeItem(`noline/${key}`);
}

export default {
    save,
    load,
    clear,
};
