import { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import $http from "@/api/http";

import { Organization } from "vacan-api";

export default class {
    public static async getOrganizationByOrganizationId(organizationId: number): Promise<Organization | undefined> {
        return await $http
            .get(`/organizations/${organizationId}`)
            .then((response: AxiosResponse) => response.data.items[0] as Organization | undefined)
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }

    public static getOrganizationListByUserId(userId: number): AxiosPromise {
        return $http.get(`/users/${userId}/organizations`);
    }
}
