import Vue, { PluginObject } from "vue";

import { ImageCollection, ImageCollectionSituation } from "vacan-api";

class PluginFilterImageCollection {
    public filteredBySituation(
        imageCollectionList: ImageCollection[],
        situation: ImageCollectionSituation,
    ): ImageCollection[] {
        return imageCollectionList
            .filter((imageCollection: ImageCollection) => imageCollection.situation === situation)
            .sort((a: ImageCollection, b: ImageCollection) => (a.displayOrder < b.displayOrder ? -1 : 1));
    }

    public imageUrlList(imageCollectionList: ImageCollection[]): string[] {
        return imageCollectionList.map((imageCollection: ImageCollection) => imageCollection.image.url);
    }
}

const install = (vue: typeof Vue) => {
    Vue.filter("imageUrlList", (imageCollectionList: ImageCollection[]) => {
        const filterImageCollection: PluginFilterImageCollection = new PluginFilterImageCollection();
        return filterImageCollection.imageUrlList(imageCollectionList);
    });
    Vue.filter("filterBySituation", (imageCollectionList: ImageCollection[], situation: ImageCollectionSituation) => {
        const filterImageCollection: PluginFilterImageCollection = new PluginFilterImageCollection();
        return filterImageCollection.filteredBySituation(imageCollectionList, situation);
    });
};

export default {
    install,
} as PluginObject<void>;
