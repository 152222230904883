import Vue, { PluginObject } from "vue";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

import { Day, Gender, Timetable, Schedule, Shop, IrregularSchedule } from "vacan-api";

export class PluginFilterSchedule {
    private holidayString: string = "定休日";

    private scheduleList: Schedule[];
    private irregularScheduleList: IrregularSchedule[];

    constructor(scheduleList: Schedule[], irregularScheduleList: IrregularSchedule[]) {
        this.scheduleList = scheduleList;
        this.irregularScheduleList = irregularScheduleList;
    }

    public static datetimeForDisplay(datetime: string): string {
        return moment(datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY/M/D H:mm");
    }

    public static dateForDisplayByDatetime(datetime: string): string {
        return moment(datetime, "YYYY-MM-DD HH:mm:ss").format("YYYY/M/D");
    }

    public static timeForDisplay(time: string): string {
        return moment(time, "HH:mm:ss").format("H:mm");
    }

    public parseForDisplayForToday(): string {
        const now: Dayjs = dayjs();
        const irregularSchedule: string | undefined = this.parseIrregularScheduleForDisplayInIrregularSchedule(now);
        if (irregularSchedule) {
            return irregularSchedule;
        } else {
            const day: Day = now.format("dddd").toLowerCase() as Day;
            return this.parseForDisplayByDay(day);
        }
    }

    public parseForDisplayByDay(day: Day): string {
        const formattedSchedule: (string | null)[] = [];
        this.getTimetableListForDay(day).forEach((timetable: Timetable) => {
            let formattedTimetableList: (string | null)[] = [];
            formattedTimetableList.push(this.genderForDisplay(timetable.gender));
            formattedTimetableList.push(this.timeRangeForDisplay(timetable));
            if (timetable.lastOrderTime !== null) {
                formattedTimetableList.push(`L.O.${PluginFilterSchedule.timeForDisplay(timetable.lastOrderTime)}`);
            }
            const formattedTimetable: string = formattedTimetableList
                .filter((timetable: string | null) => timetable !== null)
                .join("  ");
            formattedSchedule.push(formattedTimetable);
        });
        return formattedSchedule.length === 0 ? "本日休業日" : formattedSchedule.join("\n");
    }

    public getTimetableListForDay(day: Day): Timetable[] {
        return this.scheduleList
            .map((schedule: Schedule) => {
                return (day === "sunday" && schedule.sunday) ||
                    (day === "monday" && schedule.monday) ||
                    (day === "tuesday" && schedule.tuesday) ||
                    (day === "wednesday" && schedule.wednesday) ||
                    (day === "thursday" && schedule.thursday) ||
                    (day === "friday" && schedule.friday) ||
                    (day === "saturday" && schedule.saturday) ||
                    (day === "holiday" && schedule.holiday)
                    ? {
                          startTime: schedule.startTime,
                          endTime: schedule.endTime,
                          lastOrderTime: schedule.lastOrderTime,
                          gender: schedule.gender,
                      }
                    : null;
            })
            .filter<Timetable>((timetable: Timetable | null): timetable is Timetable => timetable !== null);
    }

    private genderForDisplay(gender: Gender): string | null {
        switch (gender) {
            case "common":
                return null;
            case "male":
                return "男";
            case "female":
                return "女";
        }
    }

    private timeRangeForDisplay(timetable: Timetable): string {
        return this.is24Hour(timetable)
            ? "24hours"
            : `${PluginFilterSchedule.timeForDisplay(timetable.startTime)} - ${PluginFilterSchedule.timeForDisplay(
                  timetable.endTime,
              )}`;
    }

    private is24Hour(timetable: Timetable): boolean {
        return timetable.startTime === "00:00:00" && timetable.endTime === "00:00:00";
    }

    private parseIrregularScheduleForDisplayInIrregularSchedule(now: Dayjs): string | undefined {
        if (!this.irregularScheduleList) {
            return undefined;
        }

        // filter today's irregular schedule from irregular schedule list
        const irregularScheduleListToday: IrregularSchedule[] = this.irregularScheduleList
            .filter((irregularSchedule: IrregularSchedule) => {
                return this.dayjsFromUnixtime(irregularSchedule.startUnixtime).isSame(now, "date");
            })
            .sort((e1, e2) => {
                if (e1.startUnixtime < e2.startUnixtime) {
                    return -1;
                }
                return 1;
            });

        if (!irregularScheduleListToday) {
            return undefined;
        }

        // convert each irregular schedule to string in shop card view
        const irregularScheduleStringList: string[] = irregularScheduleListToday.map(
            (irregularSchedule: IrregularSchedule) =>
                this.formatGender(irregularSchedule) + this.formatOpeningHours(irregularSchedule),
        );

        return irregularScheduleStringList !== undefined ? irregularScheduleStringList.join("\n") : undefined;
    }

    private formatGender(irregularSchedule: IrregularSchedule): string {
        switch (irregularSchedule.gender) {
            case "female":
                return "女 ";
            case "male":
                return "男 ";
            default:
                return "";
        }
    }

    private formatOpeningHours(irregularSchedule: IrregularSchedule): string {
        if (this.openAllDayOrCloseAllDay(irregularSchedule)) {
            if (irregularSchedule.businessStatus === "open") {
                return "24時間営業";
            } else {
                return "本日休業日";
            }
        }
        if (irregularSchedule.lastOrderUnixtime) {
            return `${this.dayjsFromUnixtime(irregularSchedule.startUnixtime).format("H:mm")}-${this.dayjsFromUnixtime(
                irregularSchedule.endUnixtime,
            ).format("H:mm")} (L.O.${this.dayjsFromUnixtime(irregularSchedule.lastOrderUnixtime).format("H:mm")})`;
        } else {
            return `${this.dayjsFromUnixtime(irregularSchedule.startUnixtime).format("H:mm")}-${this.dayjsFromUnixtime(
                irregularSchedule.endUnixtime,
            ).format("H:mm")}`;
        }
    }

    private openAllDayOrCloseAllDay(irregularSchedule: IrregularSchedule): boolean {
        return (
            this.dayjsFromUnixtime(irregularSchedule.startUnixtime).format("HH:mm:ss") === "00:00:00" &&
            this.dayjsFromUnixtime(irregularSchedule.endUnixtime).format("HH:mm:ss") === "23:59:59"
        );
    }

    private dayjsFromUnixtime(unixtime: number): Dayjs {
        return dayjs(unixtime * 1000);
    }
}

const install = (vue: typeof Vue) => {
    Vue.filter("today", (shop: Shop) => {
        const filterSchedule: PluginFilterSchedule = new PluginFilterSchedule(
            shop.scheduleList,
            shop.irregularScheduleList,
        );
        return filterSchedule.parseForDisplayForToday();
    });

    Vue.filter("day", (shop: Shop, day: Day) => {
        const filterSchedule: PluginFilterSchedule = new PluginFilterSchedule(
            shop.scheduleList,
            shop.irregularScheduleList,
        );
        return filterSchedule.parseForDisplayByDay(day);
    });

    Vue.filter("datetime", (datetime: string) => {
        return PluginFilterSchedule.datetimeForDisplay(datetime);
    });

    Vue.filter("date", (datetime: string) => {
        return PluginFilterSchedule.dateForDisplayByDatetime(datetime);
    });

    Vue.filter("time", (time: string) => {
        return PluginFilterSchedule.timeForDisplay(time);
    });
};

export default {
    install,
} as PluginObject<void>;
