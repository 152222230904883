import Vue from "vue";
import Vuex, { Module } from "vuex";
require("es6-promise/auto");
// import createLogger from "vuex/dist/logger";

import root, { RootState } from "@/store/modules/index";
import shop, { ShopState } from "@/store/modules/shop";
import organization from "@/store/modules/organization";
import { OrganizationState } from "@/store/modules/organization/state";
import organizationFilter from "@/store/modules/organization/filter";
import { OrganizationFilterState } from "@/store/modules/organization/filter/state";

import NolineStore, { NolineState } from "@/modules/noline/store";
import CaviStore, { CaviState } from "@/modules/cavi/store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

interface State {
    old: RootState;
    shop: ShopState;
    organization: OrganizationState & {
        filter: OrganizationFilterState;
    };
    noline: NolineState;
    cavi: CaviState;
}

export default new Vuex.Store<State>({
    modules: {
        old: root,
        shop,
        organization: {
            ...organization,
            modules: {
                filter: organizationFilter,
            },
        },
        noline: NolineStore,
        cavi: CaviStore,
    },
    strict: debug,
    // plugins: debug ? [createLogger({})] : [],
});
