import { AxiosPromise, AxiosResponse, AxiosError } from "axios";
import $http from "@/api/http";
import { Area } from "vacan-api";

export default class {
    public static async getAreaListByOrganizationId(organizationId: number): Promise<Area[]> {
        return await $http
            .get(`/organizations/${organizationId}/areas`)
            .then((response: AxiosResponse) => response.data.items as Area[])
            .catch((error: AxiosError) => {
                return error.response ? error.response.data.errors : { message: "connection error", name: "" };
            });
    }
}
