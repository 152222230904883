import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

export enum AreaType {
    MALE = "male", // 男性用トイレ
    FEMALE = "female", // 女性用トイレ
    ACCESSIBLE = "accessible", // 多目的トイレ
    NURSING_ROOM = "nursing_room", // 授乳室
    POWDER_ROOM = "powder_room", // パウダールーム / パウダーコーナー
    COMMON = /* DEPRECATED */ "common", // 多目的トイレ
}

export interface Area {
    areaId: number;
    areaName: string;
    floor: string;
    gender: AreaType;
    status: "available" | "unavailable";
    memo?: string;
    displayOrder?: number;
}

export interface VacancyArea {
    areaId: number;
    inUseCount: number;
    vacantCount: number;
    totalCount: number;
    errorCount: number;
}

const $http: AxiosInstance = axios.create({
    baseURL: "https://api.vacanservice.com/v1",
    timeout: 100000,
});

export async function fetchAreaListByStoreId(storeId: number): Promise<Area[]> {
    return await $http
        .get(`/stores/${storeId}/areas`)
        .then((response: AxiosResponse) => response.data.items as Area[])
        .catch((error: AxiosError) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}

export async function fetchVacancyAreaListByStoreId(storeId: number): Promise<VacancyArea[]> {
    return await $http
        .get(`/stores/${storeId}/vacancy`)
        .then((response: AxiosResponse) => response.data.items as VacancyArea[])
        .catch((error: AxiosError) => {
            return Promise.reject(
                error.response ? error.response.data.errors : { message: "connection error", name: "" },
            );
        });
}
