import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import types from "@/store/types";

const $http: AxiosInstance = axios.create({
    baseURL: process.env.VUE_APP_VACAN_API_BASE_URL,
    timeout: 100000,
});

export default $http;
