import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import i18n from "../i18n";

import moment from "moment";

Vue.use(VeeValidate, {
    i18nRootKey: "validation",
    i18n,
});

Validator.extend("has_uppercase", {
    getMessage: (field: string) => field + "に英大文字が含まれていません",
    validate: (value: any) => /[A-Z]/.test(value),
});
Validator.extend("has_lowercase", {
    getMessage: (field: string) => field + "に英小文字が含まれていません",
    validate: (value: any) => /[a-z]/.test(value),
});
Validator.extend("has_number", {
    getMessage: (field: string) => field + "に数字が含まれていません",
    validate: (value: any) => /[\d]/.test(value),
});
Validator.extend("time", {
    getMessage: (field: string) => field + "が時刻のフォーマットではありません",
    validate: (value: any) => moment(value, ["HH:mm", "HH:mm:ss"]).isValid(),
});
Validator.extend("mobile_phone", {
    getMessage: () => "有効な電話番号を入力してください",
    validate: (value: any) => /^0[7-9]0-[0-9]{4}-[0-9]{4}$/.test(value),
});
